import React from "react";
import { Card, Button } from "react-bootstrap";
import "./product.css";

const ProductCard = ({ title, description, price, imageUrl }) => (
  <Card className=" product_card" bg="" text="">
    <div className="card_img">
      <img src={imageUrl} alt={description} />
    </div>
    <Card.Body className="pt-2">
      <Card.Title className="fs-5">{title}</Card.Title>
      <Card.Text className="fs-5 mb-0">Price: uzs {price}</Card.Text>
      <Card.Text className="fs-6 mb-2">{description}</Card.Text>
    </Card.Body>
    <Card.Footer>
      <Button
        className=" border-none mx-auto py-2 mx-2 d-block w-100"
        variant="primary"
      >
        Purchase now
      </Button>
    </Card.Footer>
  </Card>
);
export default ProductCard;
