import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";

import { Autoplay, Navigation, EffectFade } from "swiper";

import DonateModal from "../Modal/DonateModal";

import slide1 from "../../assets/images/main-slider/slider1/slider-bg1.jpg";
import slide2 from "../../assets/images/main-slider/slider1/slider-bg2.jpg";
import slide3 from "../../assets/images/main-slider/slider1/slider-bg3.jpg";
import shape1 from "../../assets/images/main-slider/slider1/shape1.svg";
import line1 from "../../assets/images/main-slider/slider1/shape-line1.svg";
import shape2 from "../../assets/images/main-slider/slider1/shape2.svg";
import line2 from "../../assets/images/main-slider/slider1/shape-line2.svg";
import pic1 from "../../assets/images/main-slider/slider1/pic1.jpg";
import pic2 from "../../assets/images/main-slider/slider1/pic2.jpg";
import pic3 from "../../assets/images/main-slider/slider1/pic3.jpg";
import pic4 from "../../assets/images/main-slider/slider1/pic4.jpg";
import pic5 from "../../assets/images/main-slider/slider1/pic5.jpg";
import pic6 from "../../assets/images/main-slider/slider1/pic6.jpg";
import pic7 from "../../assets/images/main-slider/slider1/pic7.jpg";
import pic8 from "../../assets/images/main-slider/slider1/pic8.jpg";
import pic9 from "../../assets/images/main-slider/slider1/pic9.jpg";
import { useTranslation } from "react-i18next";

const dataBlog = [
  {
    title1: "GIVE A HAND TO MAKE",
    title2: "Your Donation Can Change the World ",
    baner: slide1,
    img2: pic1,
    img3: pic2,
    img4: pic3,
  },
  {
    title1: "Empower Your Startup with Investhub",
    title2: "Start raising capital today",
    baner: slide2,
    img2: pic4,
    img3: pic5,
    img4: pic6,
  },
  {
    title1: "CHILD THE OF EDUCATION",
    title2: "Give  a Child the of Education",
    baner: slide3,
    img2: pic7,
    img3: pic8,
    img4: pic9,
  },
];

const RaisingMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="banner-inner d-flex justify-content-end">
        <div className="container py-3 py-md-0 my-0 my-md-5">
          <div className="row d-flex ">
            <div className="col-lg-8 col-md-12 order-2 order-md-1">
              <div className="banner-content ps-0">
                <div className="top-content">
                  <h5 className="sub-title text-secondary fs-3">
                    {t("invest-in")}{" "}
                  </h5>
                  <h1 className="title fs-2">{t("ivest-idea")} </h1>
                  <p className="fs-5">{t("invest-desc")} </p>
                  <div className="d-flex align-items-center">
                    <Link
                      to={"/about-us"}
                      className="btn btn-secondary btnhover2"
                    >
                      {t("submit-a")}{" "}
                      <i className="flaticon-right-arrows ms-3"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 order-1 order-md-2">
              <div className="my-4 my-md-5 row ">
                <div className="col-12">
                  <div className="w-100">
                    <img src={pic1} alt="" className="w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RaisingMain;
