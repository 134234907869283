/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, Image, Row } from "react-bootstrap";
import "./report.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BanerLogoSlider from "../Home/BanerLogoSlider";
import { IMAGES } from "..//..//constant/theme";
import main from "..//..//assets/images/main-slider/pic1.png";
import { getReport } from "../../api/getData/Report";
import { useTranslation } from "react-i18next";
import DataNotFound from "../DataNotFound/DataNotFound";
import SearchBar from "./SearchReport";
import FilterSidebar from "./FilterPanel";
import { useDispatch, useSelector } from "react-redux";
import { saveReport } from "../../features/slice/report";

const reportData = [
  {
    id: 1,
    title: "The State Of Indian Startup Ecosystem Report H1 2024",
    date: "4th September 2024 | 17 Pages",
    imageUrl: "https://via.placeholder.com/300x200.png?text=Startup+Ecosystem",
  },
  {
    id: 2,
    title:
      "State Of Indian Ecommerce Report H1/2024, In-Focus: Beauty & Personal Care",
    date: "13th August 2024 | 7 Pages",
    imageUrl: "https://via.placeholder.com/300x200.png?text=Ecommerce+Report",
  },
  {
    id: 3,
    title: "India Tech Startup Funding Report H1 2024",
    date: "5th July 2024 | xx Pages", // Replace xx with actual page number
    imageUrl: "https://via.placeholder.com/300x200.png?text=Tech+Funding",
  },
];

export default function ReportList() {
  const { i18n } = useTranslation();
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState("");
  const [report, setReport] = useState(null);
  const modalRef = useRef();
  const navigate = useNavigate();
  const navFun = (item) => {
    navigate(`/report/${item}`);
  };
  //
  const showFilter = () => {
    setShow(true);
  };
  //
  const dispatch = useDispatch();
  const report_data = useSelector((state) => state.report.report_data);
  const fetchReport = async (language, query) => {
    try {
      const res = await getReport(language, query);
      console.log(res);
      dispatch(saveReport(res?.data?.results));
      setReport(res?.data?.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchReport(i18n.language, query);
  }, [i18n.language]);

  const [category, setCategory] = useState("");

  const handelCategory = (selectedCategory) => {
    setCategory(selectedCategory);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Qidiruv funksiyasini yozing
    console.log(`Searching for: ${query} in category: ${category}`);
  };

  useEffect(() => {
    if (query.length > 2) {
      axios
        .get(`https://api.example.com/search?q=${query}&filter=${filter}`)
        .then((response) => {
          setResults(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setResults([]);
    }
  }, [query, filter]);
  return (
    <div>
      <div className="banner-one">
        <div className="container">
          <div className="row banner-inner">
            <div className="col-lg-8 col-md-7">
              <div className="banner-content">
                <h5 className="sub-title text-primary">Crowdfunding</h5>
                <h1 className="title">
                  We Help Surface Innovations In Technology
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip
                </p>
                <Link
                  to={"/about-us"}
                  className="btn btn-secondary btnhover2 m-r15"
                >
                  Learn More{" "}
                  <i className="flaticon-right-arrows ms-3 scale1"></i>
                </Link>
                <Link
                  to={"#"}
                  className="btn btn-light"
                  onClick={() => {
                    modalRef.current.handleModalOpen();
                  }}
                  data-bs-target="#modalDonate"
                >
                  Donate
                </Link>
                <BanerLogoSlider />
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="banner-media">
                <img src={main} className="main-img" alt="" />
                <div className="dz-media">
                  <img src={IMAGES.Shape} className="main-img" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="dz-shape">
            <img src={IMAGES.Shape1} className="shape-1 move-2" alt="shape" />
            <img src={IMAGES.Shape2} className="shape-2 move-2" alt="shape" />
            <img src={IMAGES.Shape1} className="shape-3 move-2" alt="shape" />
            <img src={IMAGES.Shape2} className="shape-4 move-2" alt="shape" />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row d-flex g justify-content-between">
          <div className={`${show ? "col-lg-3" : "d-none"} filter-show`}>
            <div className="d-flex _dh5" onClick={() => setShow(false)}>
              <svg
                width={17}
                height={14}
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L11 13"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M4 4L1.35355 6.64645C1.15829 6.84171 1.15829 7.15829 1.35355 7.35355L4 10"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M2 7H8"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              <h5>Advanced filter</h5>
            </div>
            <FilterSidebar />
          </div>
          <div className={`${show ? "col-lg-8" : "col-lg-12"}`}>
            <div className="row align-items-center  search-filter">
              <div className={`${show ? "d-none" : "col-lg-4"}`}>
                {show ? (
                  ""
                ) : (
                  <div className="filter-button">
                    <button onClick={showFilter}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 15 11"
                        fill="white"
                      >
                        <path
                          d="M8.29031 2.6648H0.592165C0.435113 2.6648 0.284494 2.60241 0.173441 2.49136C0.0623886 2.38031 0 2.22969 0 2.07263C0 1.91558 0.0623886 1.76496 0.173441 1.65391C0.284494 1.54286 0.435113 1.48047 0.592165 1.48047H8.29031C8.44737 1.48047 8.59799 1.54286 8.70904 1.65391C8.82009 1.76496 8.88248 1.91558 8.88248 2.07263C8.88248 2.22969 8.82009 2.38031 8.70904 2.49136C8.59799 2.60241 8.44737 2.6648 8.29031 2.6648ZM13.6198 2.6648H11.2511C11.0941 2.6648 10.9435 2.60241 10.8324 2.49136C10.7214 2.38031 10.659 2.22969 10.659 2.07263C10.659 1.91558 10.7214 1.76496 10.8324 1.65391C10.9435 1.54286 11.0941 1.48047 11.2511 1.48047H13.6198C13.7769 1.48047 13.9275 1.54286 14.0385 1.65391C14.1496 1.76496 14.212 1.91558 14.212 2.07263C14.212 2.22969 14.1496 2.38031 14.0385 2.49136C13.9275 2.60241 13.7769 2.6648 13.6198 2.6648Z"
                          fill="white"
                        />
                        <path
                          d="M9.77073 4.14516C9.36081 4.14516 8.9601 4.0236 8.61926 3.79587C8.27843 3.56813 8.01278 3.24443 7.85591 2.86572C7.69905 2.48701 7.658 2.07028 7.73797 1.66824C7.81794 1.2662 8.01534 0.8969 8.30519 0.607045C8.59505 0.31719 8.96435 0.119796 9.36639 0.0398249C9.76843 -0.040146 10.1852 0.000898013 10.5639 0.157767C10.9426 0.314635 11.2663 0.580282 11.494 0.921116C11.7218 1.26195 11.8433 1.66266 11.8433 2.07258C11.8433 2.62226 11.6249 3.14943 11.2363 3.53811C10.8476 3.9268 10.3204 4.14516 9.77073 4.14516ZM9.77073 1.18433C9.59505 1.18433 9.42332 1.23643 9.27724 1.33403C9.13117 1.43163 9.01732 1.57036 8.95009 1.73266C8.88286 1.89497 8.86527 2.07357 8.89955 2.24587C8.93382 2.41817 9.01842 2.57644 9.14264 2.70067C9.26687 2.82489 9.42514 2.90949 9.59744 2.94376C9.76974 2.97803 9.94834 2.96044 10.1106 2.89321C10.273 2.82598 10.4117 2.71213 10.5093 2.56606C10.6069 2.41999 10.659 2.24826 10.659 2.07258C10.659 1.837 10.5654 1.61107 10.3988 1.44449C10.2322 1.27791 10.0063 1.18433 9.77073 1.18433ZM13.6198 8.5864H5.92165C5.7646 8.5864 5.61398 8.52401 5.50293 8.41296C5.39188 8.3019 5.32949 8.15128 5.32949 7.99423C5.32949 7.83718 5.39188 7.68656 5.50293 7.57551C5.61398 7.46446 5.7646 7.40207 5.92165 7.40207H13.6198C13.7769 7.40207 13.9275 7.46446 14.0385 7.57551C14.1496 7.68656 14.212 7.83718 14.212 7.99423C14.212 8.15128 14.1496 8.3019 14.0385 8.41296C13.9275 8.52401 13.7769 8.5864 13.6198 8.5864ZM2.96083 8.5864H0.592165C0.435113 8.5864 0.284494 8.52401 0.173441 8.41296C0.0623886 8.3019 0 8.15128 0 7.99423C0 7.83718 0.0623886 7.68656 0.173441 7.57551C0.284494 7.46446 0.435113 7.40207 0.592165 7.40207H2.96083C3.11788 7.40207 3.2685 7.46446 3.37955 7.57551C3.4906 7.68656 3.55299 7.83718 3.55299 7.99423C3.55299 8.15128 3.4906 8.3019 3.37955 8.41296C3.2685 8.52401 3.11788 8.5864 2.96083 8.5864Z"
                          fill="white"
                        />
                        <path
                          d="M4.44172 10.0668C4.0318 10.0668 3.63109 9.94523 3.29026 9.7175C2.94942 9.48976 2.68378 9.16607 2.52691 8.78735C2.37004 8.40864 2.32899 7.99191 2.40897 7.58987C2.48894 7.18783 2.68633 6.81853 2.97619 6.52868C3.26604 6.23882 3.63534 6.04143 4.03738 5.96146C4.43942 5.88148 4.85615 5.92253 5.23486 6.0794C5.61358 6.23627 5.93727 6.50191 6.16501 6.84275C6.39274 7.18358 6.5143 7.58429 6.5143 7.99421C6.5143 8.54389 6.29594 9.07106 5.90725 9.45974C5.51857 9.84843 4.9914 10.0668 4.44172 10.0668ZM4.44172 7.10596C4.26604 7.10596 4.09431 7.15806 3.94824 7.25566C3.80216 7.35326 3.68832 7.49199 3.62109 7.65429C3.55386 7.8166 3.53627 7.9952 3.57054 8.1675C3.60481 8.3398 3.68941 8.49807 3.81363 8.6223C3.93786 8.74652 4.09613 8.83112 4.26843 8.86539C4.44073 8.89966 4.61933 8.88207 4.78164 8.81484C4.94394 8.74761 5.08267 8.63377 5.18027 8.48769C5.27787 8.34162 5.32997 8.16989 5.32997 7.99421C5.32997 7.75863 5.23638 7.5327 5.06981 7.36612C4.90323 7.19955 4.6773 7.10596 4.44172 7.10596Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Filters
                    </button>
                  </div>
                )}
              </div>
              <div className={`${show ? "col-lg-12" : "col-lg-8"}`}>
                <SearchBar />
              </div>
            </div>
            <div className="row my-4 d-flex  ">
              {report_data && report_data.length > 0 ? (
                report_data &&
                report_data.map((report, index) => (
                  <div
                    className={`${show ? "col-lg-6" : "col-lg-4"} my-2 my-md-3`}
                    key={report.id}
                  >
                    <Card className="mb-3 max-height">
                      <div
                        className="w-100"
                        style={{
                          height: "270px",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          className="w-100 h-100"
                          style={{ objectFit: "cover" }}
                          src={report.image}
                          alt={report.title}
                          onClick={() => navFun(index)}
                        />
                      </div>

                      <Card.Body>
                        <Card.Title className=" _title">
                          {report.title}
                        </Card.Title>
                      </Card.Body>
                      <Card.Footer className="card-footer d-flex align-items-center justify-content-between">
                        <Card.Text className=" _date">
                          {report?.publish_date} | {report?.page_count} Pages
                        </Card.Text>
                        <i className="fas fa-download _icon  "></i>
                      </Card.Footer>
                    </Card>
                  </div>
                ))
              ) : (
                <DataNotFound />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
