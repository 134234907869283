import React from "react";
import ProductCard from "./ProductCard";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import "./product.css"
const products = [
  {
    title: "Database Benchmarking Tool Templates & Discounts",
    description: "Description for product 1",
    price: "19",
    imageUrl: "https://via.placeholder.com/150", // Replace with your image URL
  },
  {
    title: "YC Research Automation",
    description: "Description for product 2",
    price: "19",
    imageUrl: "https://via.placeholder.com/150", // Replace with your image URL
  },
  {
    title: "Rapid Company Generation in Automation",
    description: "Description for product 3",
    price: "19",
    imageUrl: "https://via.placeholder.com/150", // Replace with your image URL
  },
];
export default function ProductList() {
  return (
    <Container fluid className="my-3 fluid">
      <h1 className="fs-2">Our most popular products</h1>
      <Row className="my-4">
        <Col md={3}>
        <div>
      <h4>Filters</h4>
      <ListGroup className="group-list">
        <ListGroup.Item className="list-item" action>Database Benchmarking</ListGroup.Item>
        <ListGroup.Item className="list-item" action>Tool Finder & Discounts</ListGroup.Item>
        <ListGroup.Item className="list-item" action>Notion Templates</ListGroup.Item>
        <ListGroup.Item className="list-item" action>Automation Templates</ListGroup.Item>
        <ListGroup.Item className="list-item" action>Show all products</ListGroup.Item>
      </ListGroup>
    </div>
        </Col>
        <Col md={9} className="">
          <Row>
            {products.map((product, index) => (
              <Col md={4} className="mb-4 md-mb-0">
                <ProductCard
                  key={index}
                  title={product.title}
                  description={product.description}
                  price={product.price}
                  imageUrl={product.imageUrl}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
