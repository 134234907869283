import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    report_data:[1,2,3,4],
}
export const Report = createSlice({
    name:"Report",
    initialState,
    reducers:{
        saveReport:(state, action)=>{
        state.report_data = action.payload;
    }}
})
export const  {saveReport} = Report.actions;
export default Report.reducer;