import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "./product.css"
const ProductMain = () => (
  <section className='section-product'>

  <Container  className='main_product'>
    <Row className='mx-auto'>
      <Col xs={12} md={6} className='text-center mx-auto'>
        <h1 className='title fs-1'>Products & Tools</h1>
        <p>It didn't exist, so we built it. Products and tools to kickstart your digitization journey. Get started with individual products below or the full bundle via The Lab.</p>
        <Button variant="primary">Access all products</Button>
      </Col>
     
    </Row>
  </Container>
  </section>
);

export default ProductMain;
