import React from 'react'
import ReportList from '../components/Report/ReportList'
import ScrollSpyComponent from '../components/Scrollspy/Scrollspy'

export default function Report() {
  return (
    <div>
      <ReportList/>
      <ScrollSpyComponent/>
    </div>
  )
}
