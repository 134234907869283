import React from 'react'
import GlossaryList from '../components/Glossary/GlossaryList'

export default function Glossary() {
  return (
    <div style={{position: "relative"}}>
      <GlossaryList/>
    </div>
  )
}
