import React, { useState } from "react";
import "./report.css";
import { getReport, getSeachData } from "../../api/getData/Report";
import { useDispatch, useSelector } from "react-redux";
import { saveReport } from "../../features/slice/report";
import { useTranslation } from "react-i18next";
const SearchBar = () => {
  const dispatch = useDispatch();
  const {i18n}  = useTranslation()
  const [query, setQuery] = useState("");
  const handleInputChange = async (e) => {
    const input = e.target.value;
    setQuery(input);
    try {
      const res = await getSeachData(query?input:query);
      dispatch(saveReport(res?.data?.results));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = () => {
    console.log("Searching for:", query);
  };
  const handleClear = async() => {
    setQuery("");
    try {
      const res = await getReport(i18n.language);
      dispatch(saveReport(res?.data?.results))
    } catch (error) {
      console.log(error)
    }
    
  };
  return (
    <div className="search-bar">
      <button className="search-button" onClick={handleSearch}>
        <i className="fa fa-search"></i>
      </button>
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={handleInputChange}
      />

      {query === "" ? (
        ""
      ) : (
        <button className="clear-button" onClick={handleClear}>
          <i className="fa fa-times"></i>
        </button>
      )}
    </div>
  );
};

export default SearchBar;
