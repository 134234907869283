/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import BanerLogoSlider from "./../components/Home/BanerLogoSlider";

import TestimonialSlider from "./../components/Home/TestimonialSlider";
import CounterBlog from "./../components/Home/CounterBlog";
import ServiceBlog from "../components/Home2/ServiceBlog";
//images
import main from "./../assets/images/main-slider/pic1.png";
import bg10 from "../assets/images/background/bg10.png";
import shape3 from "../assets/images/side-images/shape3.png";
import { IMAGES } from "../constant/theme";
import FaqComponent from "../components/FaqComponent";
import ContactComponent from "../components/ContactComponent";
import ThreestepBlog from "../components/Home2/ThreestepBlog";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showLogin } from "../features/slice/showLogin";
import { useTranslation } from "react-i18next";
import PartnershipSlider from "../components/PartnershipSlider";

const Home = () => {
  const modalRef = useRef();
  const { changeBackground, changePrimaryColor } = useContext(ThemeContext);
  const { t } = useTranslation();
  useEffect(() => {
    changeBackground({ value: "default", label: "default" });
    changePrimaryColor("color-skin-1");
  }, []);
  //Accord const
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState("");
  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(event.target.value);
    const apiUrl = value
      ? `https://api.investmap.uz/api/project/search/?search=${value}`
      : "https://api.investmap.uz/api/project/visible/";
  };
  const handleSearch = (e) => {
    e.preventDefault();
    console.log("salom");
  };
  const handelCategory = (category) => {
    setCategory(category);
  };
  const [category, setCategory] = useState("All Category");
  const [blog, setBlog] = useState(null);
  const api_url = process.env.REACT_APP_INVEST_MAP_API;
  const dispatch = useDispatch();
  const getData = async () => {
    try {
      const res = await axios.get(`${api_url}/api/blog`);
      if (res) {
        setBlog(res?.data);
        console.log(res);
      } else {
        console.log("Data not found");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  //Data
  const text = t("mostAskedQuestions");
  const accordBlog = [
    {
      title: t("whatIsInvestmap"),
      answer: t("whatIsInvestmapDesc"),
    },
    {
      title: t("joinInvestmap"),
      answer: t("joinInvestmapDesc"),
    },
    {
      title: t("minimumInvestment"),
      answer: t("minimumInvestmentDesc"),
    },
    {
      title: t("startupSelection"),
      answer: t("startupSelectionDesc"),
    },
    {
      title: t("typesOfStartups"),
      answer: t("typesOfStartupsDesc"),
    },
    {
      title: t("investmentSafety"),
      answer: t("investmentSafetyDesc"),
    },
    {
      title: t("trackProgress"),
      answer: t("trackProgressDesc"),
    },
    {
      title: t("fundingGoal"),
      answer: t("fundingGoalDesc"),
    },
    {
      title: t("fees"),
      answer: t("feesDesc"),
    },
    {
      title: t("support"),
      answer: t("supportDesc"),
    },
  ];
  return (
    <>
      <div className="page-content bg-white">
        <div className="">
          <div className="container pt-4 py-md-5 ">
            <div className="row d-flex justify-content-beetwen align-items-center">
              <div className="col-lg-9 col-md-7 order-md-1 order-2 mt-4 md-my-0">
                <div className="banner-content">
                  <h1 className="title" style={{ whiteSpace: "break-spaces" }}>
                    {t("head")}
                  </h1>
                  <p>{t("main-text")}</p>
                  <Link
                    to={"/about-us"}
                    className="btn btn-secondary btnhover2 me-3 "
                  >
                    {t("viewForFunding")}
                    <i className="flaticon-right-arrows ms-3 "></i>
                  </Link>
                  <Link
                    to="/investor"
                    className="btn btn-light my-4"
                    data-bs-target="#modalDonate"
                  >
                    {t("viewInvestors")}
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 d-flex justify-content-center order-md-2 order-1">
                <div className="w-100 d-flex justify-content-md-end justify-content-center">
                  <img src={IMAGES.Shape} className="w-100" alt="" />
                </div>
              </div>
            </div>
            <div className="dz-shape"></div>
          </div>
        </div>

        <div className="counter-wrapper-1 content-inner-3 py-4">
          <div className="container">
            <div className="counter-inner bg-secondary">
              <div className="row">
                <CounterBlog />
              </div>
              <img src={IMAGES.Pattren1} className="pattren1 move-2" alt="" />
              <img src={IMAGES.Pattren2} className="pattren2 move-2" alt="" />
              <img src={IMAGES.Pattren3} className="pattren3 move-2" alt="" />
              <img src={IMAGES.Pattren4} className="pattren4 move-2" alt="" />
              <img src={IMAGES.Pattren5} className="pattren5 move-2" alt="" />
              <img src={IMAGES.Pattren6} className="pattren6 move-2" alt="" />
            </div>
          </div>
        </div>
        <section className="clearfix">
          <div className="container-fluid">
            <div
              className="content-inner bg-gray py-4"
              style={{
                backgroundImage: "url(" + bg10 + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div
                  className="section-head text-center wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <h2 className="title">{t("whyInvestmap")} </h2>
                </div>
                <div className="row justify-content-center">
                  <ServiceBlog />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-1 section-wrapper3 ">
          <div className="container-fluid">
            <div className="section-head text-center">
              <h2 className="title">{t("startFundraising")}</h2>
            </div>
            <div className="row justify-content-center">
              <ThreestepBlog />
            </div>
            <div
              className="text-center btn-bottom wow fadeInUp"
              data-wow-delay="0.8s"
            >
              <Link
                onClick={() => dispatch(showLogin(true))}
                className="btn btn-primary mt-5 "
              >
                {t("registerProject")}
              </Link>
            </div>
            <img src={shape3} alt="images" className="img-2" />
          </div>
        </section>
        <PartnershipSlider/>
        <section className="testimonial-wrapper1 content-inner">
          <div
            className="section-head text-center wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <h2 className="title">{t("testimonials")} </h2>
          </div>
          <TestimonialSlider />
        </section>
        <FaqComponent accordBlog={accordBlog} text={text} />
        <ContactComponent />
      </div>
    </>
  );
};
export default Home;
