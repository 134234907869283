import React from "react";
import { Container, Row, Col, Card, Button, Image, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function ReportDetail() {
  const data = [
    {
      icon: "fas fa-chart-line",
      value: "$400 Bn+",
      description: "Estimated Ecommerce Market Opportunity By 2030",
    },
    {
      icon: "fas fa-chart-line",
      value: "$28 Bn+",
      description: "Indian Beauty & Personal Care Market Opportunity By 2030",
    },
    {
      icon: "fas fa-chart-line",
      value: "$360 Mn+",
      description: "Market Opportunity For AI In India Online BPC Segment",
    },
    {
      icon: "fas fa-chart-line",
      value: "$561 Mn+",
      description: "Raised By Ecommerce Startups In H1 2024",
    },
    {
      icon: "fas fa-chart-line",
      value: "D2C",
      description: "The Most Funded Subsector In H1 2024",
    },
    {
      icon: "fas fa-chart-line",
      value: "Bengaluru",
      description: "The Top Ecommerce Hub In H1 2024",
    },
  ];
  const reports = [
    {
      title: "The State Of Indian Startup Ecosystem Report 2024",
      date: "5th September 2024",
      description: "117 Pages",
      imageUrl: "https://picsum.photos/200/300?random=1",
    },
    {
      title:
        "State Of Indian Ecommerce Report H1 2024, In-focus: Beauty & Personal Care",
      date: "12th August 2024",
      description: "57 Pages",
      imageUrl: "https://picsum.photos/200/300?random=2",
    },
    {
      title: "Indian Tech Startup Funding Report H1 2024",
      date: "9th July 2024",
      description: "54 Pages",
      imageUrl: "https://picsum.photos/200/300?random=3",
    },
  ];

  return (
    <div>
      <Container className="my-5 shadow py-5 px-md-5 px-3">
        <Row className="d-flex gap-md-0 gap-5 ">
          {/* Left Column (col-md-9) */}
          <Col md={8} className="order-md-1 order-2">
          
          <Link className="" to="/report">Report /</Link>
            {/* About Section */}
            <h3 className="title">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Aspernatur, molestias.
            </h3>

            <div className="d-flex justify-content-between align-items-center mt-5">
              {/* Left-side buttons */}
              <div>
                <Button variant="primary" className="me-2">
                  View Report
                </Button>
                <Button variant="outline-secondary" className="">
                  Download Report
                </Button>
              </div>

              {/* Right-side share button */}
              <div>
                <Button variant="outline-secondary">
                  <i className="fas fa-share-alt fs-5"></i>
                </Button>
              </div>
            </div>
          </Col>

          {/* Right Column (col-md-3) */}
          <Col
            md={4}
            className="order-md-2 order-1 d-flex  align-items-center justify-content-center"
          >
            <Image
              src="https://via.placeholder.com/400x200" // Replace this with actual image URL
              alt="Report Image"
              rounded
              fluid
              className="w-100 h-100 object-cover"
            />
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={12} className="card">
            <h3>About The Report</h3>
            <p>
              The Indian ecommerce market is projected to reach a total
              addressable value of $400 Bn+ by 2030, growing at a CAGR of 19%
              from 2023 to 2030. Between 2014 and H1 2024, the sector attracted
              over $34 Bn in investments, fostering 25 unicorns and 19
              soonicorns. However, in H1 2024, funding in the Indian ecommerce
              sector declined by 53% year-on-year, with the median ticket size
              for late-stage investments dropping by 32% during the same period.
              This report examines the state of the ecommerce sector in H1 2024,
              with a focus on emerging startups and the impact of GenAI in
              India's beauty and personal care segment.
            </p>
          </Col>
          <Col md={12}>
            <Row>
              {data.map((item, index) => (
                <Col sm={4} key={index} className="mb-4">
                  <Card className="text-center shadow-sm py-3">
                    <i
                      className={`${item.icon} text-primary`}
                      style={{ fontSize: "24px" }}
                    ></i>
                    <h5 className="my-2">{item.value}</h5>
                    <p className="text-muted">{item.description}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <Container className="my-5">
        <Row>
          <Col md={7} className="r_summary">
            <h4 className="_h4">Report Summary</h4>
            <Card className="p-3">
              <div className="d-flex justify-content-between align-items-center">
                <i className="fas fa-arrow-left"></i>
                <img
                  src="https://via.placeholder.com/400x300" // Report image placeholder
                  alt="Report Summary"
                  className="img-fluid"
                />
                <i className="fas fa-arrow-right"></i>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <span className="mx-1">●</span>
                <span className="mx-1">●</span>
                <span className="mx-1">●</span>
              </div>
            </Card>
          </Col>

         
          <Col md={5}>
            <h4>Table Of Contents</h4>
            <Card className="p-3">
              <ul className="list-unstyled">
                <li>India’s Digital Economy In A Nutshell</li>
                <li>Ecommerce Market In India: A Snapshot</li>
                <li>India’s Ecommerce Landscape: Key Players</li>
                <li>India’s Ecommerce Opportunity</li>
                <li>Global Ecommerce Ecosystem: India Vs China Vs The US</li>
                <li>India’s Ecommerce Ecosystem: Funding Trends</li>
                <li>InFocus: Beauty & Personal Care</li>
                <li>The Market Opportunity</li>
                <li>Landscape</li>
                <li>Funding Analysis</li>
                <li>Applications Of AI In Beauty & Personal Care</li>
                <li>Financial Analysis Of Indian Ecommerce Unicorns</li>
                <li>Key Ecommerce Trends To Look Out For</li>
                <li>Ecommerce Startups’ Stock Performance</li>
              </ul>
            </Card>
          </Col>
        </Row>

    
        <Row className="mt-4">
          <Col className="d-flex justify-content-center">
            <Button variant="primary" className="me-2 text-light">
              Download Report
            </Button>
            <Button variant="outline-danger" className=" text-dark">
              View Report
            </Button>
          </Col>
        </Row>
      </Container> */}
      <Container className="my-3">
        <div className="d-flex align-items-center justify-content-between my-4 ">
          <h3>Latest Inc42 Reports</h3>
          <Button variant="danger" className="text-light">
            View More Pages
          </Button>
        </div>
        <Row>
          {reports.map((report, index) => (
            <Col md={4} key={index}>
              <Card className="max-height">
                <div className="w-100" style={{ height: "270px" }}>
                  <Card.Img
                    variant="top"
                    className="w-100 h-100 object-cover"
                    src={report.imageUrl}
                  />
                </div>
                <Card.Body>
                  <Card.Title className="_title">{report.title}</Card.Title>
                </Card.Body>
                <Card.Footer className="_date">
                  {report.date} | {report.description}
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
