import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Svg1 = (
  <svg
    width="60"
    height="52"
    viewBox="0 0 60 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.2617 44.1014C39.7379 44.1014 39.2212 43.8615 38.8858 43.4079C38.3249 42.6488 38.4857 41.5787 39.2449 41.018C46.6367 35.5566 51.6673 30.7706 53.7932 27.1772C55.7479 23.873 56.582 20.8238 56.582 16.9815C56.582 7.76826 50.0773 3.6442 43.6309 3.6442C37.6911 3.6442 35.0672 6.89463 34.0319 8.83197C33.6643 9.51986 32.9504 9.9476 32.1685 9.94818C32.168 9.94818 32.1674 9.94818 32.1667 9.94818C31.3855 9.94818 30.6716 9.52162 30.3033 8.83479C29.264 6.89615 26.6318 3.64408 20.6815 3.64408C14.4216 3.64408 9.63368 7.23869 8.18606 13.0251C7.95707 13.9405 7.0293 14.4972 6.11348 14.2682C5.19789 14.0392 4.64125 13.1111 4.87036 12.1956C6.68934 4.92447 12.8955 0.226074 20.6815 0.226074C26.8206 0.226074 30.2941 2.98994 32.1652 5.43576C34.0319 2.98959 37.4984 0.226074 43.6307 0.226074C48.0409 0.226074 52.1 1.7692 55.0601 4.57115C58.2916 7.62975 59.9996 11.921 59.9996 16.9812C59.9996 21.419 58.9925 25.0999 56.7346 28.917C53.7571 33.9499 46.6844 39.7701 41.2757 43.7664C40.9703 43.9926 40.6144 44.1014 40.2617 44.1014Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M11.2156 20.3647C15.6523 20.3647 17.6429 22.8516 18.4262 24.3122C18.5247 24.4956 18.7916 24.4954 18.8897 24.3119C19.6702 22.8511 21.65 20.3647 26.0863 20.3647C31.1402 20.3647 35.5858 23.8147 35.5858 30.1143C35.5858 32.6635 35.0663 34.8474 33.6244 37.2846C30.6861 42.2515 21.0064 48.6901 19.018 49.9589C18.7983 50.0992 18.5199 50.0996 18.2996 49.9597C16.3097 48.6971 6.62816 42.2903 3.66695 37.2845C2.22484 34.8476 1.70898 32.6636 1.70898 30.1144C1.70898 23.8147 6.16188 20.3647 11.2156 20.3647Z"
      fill="#F7CF47"
    />
    <path
      d="M18.6579 51.7735C18.2063 51.7735 17.7656 51.6453 17.3836 51.4029C16.1793 50.6388 5.51988 43.7739 2.19586 38.1551C0.677227 35.5877 0 33.1081 0 30.1145C0 26.6664 1.1734 23.7346 3.39293 21.6356C5.42496 19.7141 8.20301 18.6559 11.2155 18.6559C14.9923 18.6559 17.3152 20.2428 18.6562 21.7121C19.9946 20.2425 22.3136 18.6559 26.0863 18.6559C29.0985 18.6559 31.8755 19.7142 33.9059 21.6361C36.1228 23.7344 37.2947 26.6664 37.2947 30.1144C37.2947 33.1038 36.6164 35.5834 35.0953 38.1548C31.7955 43.7326 21.1414 50.6313 19.9375 51.3995C19.5537 51.6443 19.1114 51.7735 18.6579 51.7735ZM11.2155 22.0738C7.33418 22.0738 3.41789 24.5601 3.41789 30.1145C3.41789 32.4929 3.93223 34.3772 5.13762 36.4147C7.40379 40.2456 14.4473 45.4298 18.656 48.1584C22.6461 45.5519 29.9057 40.2139 32.1533 36.4147C33.3612 34.3728 33.8764 32.4886 33.8764 30.1145C33.8764 24.5601 29.9637 22.0738 26.0859 22.0738C22.7277 22.0738 21.1384 23.7288 20.3966 25.1174C20.0536 25.7596 19.3871 26.1588 18.6577 26.1588C17.9295 26.1588 17.2637 25.7608 16.9202 25.1203C16.1749 23.7302 14.5806 22.0738 11.2155 22.0738Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg2 = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0001 60C21.9868 60 14.4532 56.8794 8.78679 51.213C-2.91005 39.516 -2.91005 20.4837 8.78679 8.78672C14.4532 3.12059 21.9868 0 30.0001 0C38.0133 0 45.547 3.12059 51.2133 8.78695C62.9102 20.484 62.9102 39.5163 51.2133 51.2133C45.547 56.8794 38.0135 60 30.0001 60ZM30.0001 3.57598C22.942 3.57598 16.3064 6.32449 11.3156 11.3155C1.0128 21.618 1.0128 38.382 11.3156 48.6845C16.3066 53.6755 22.9422 56.424 30.0003 56.424C37.0584 56.424 43.694 53.6755 48.6848 48.6845C58.9876 38.382 58.9876 21.618 48.6848 11.3155C43.6939 6.32449 37.0582 3.57598 30.0001 3.57598ZM30.0001 47.0007C25.6446 47.0007 21.2889 45.3429 17.973 42.0271C11.3414 35.3952 11.3414 24.6049 17.973 17.973C24.6046 11.3414 35.3951 11.3414 42.027 17.973C42.7252 18.6712 42.7252 19.8034 42.027 20.5018C41.3288 21.1999 40.1967 21.1999 39.4982 20.5018C34.2609 15.2641 25.7388 15.2643 20.5013 20.5018C15.264 25.7392 15.264 34.2612 20.5013 39.4985C25.7387 44.7361 34.2608 44.7359 39.4982 39.4985C40.1964 38.8004 41.3288 38.8004 42.0268 39.4985C42.725 40.1967 42.725 41.3289 42.0268 42.0273C38.7113 45.3429 34.3556 47.0007 30.0001 47.0007Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M40.9401 22.0428L37.9576 19.0603C36.921 18.0237 36.921 16.3433 37.9576 15.3068L45.5557 7.70867C46.5922 6.67214 48.2726 6.67214 49.3092 7.70867L52.2917 10.6912C53.3282 11.7277 53.3282 13.4082 52.2917 14.4447L44.6936 22.0428C43.6572 23.0793 41.9766 23.0793 40.9401 22.0428Z"
      fill="#F7CF47"
    />
    <path
      d="M42.8169 24.6083C41.6303 24.6083 40.5148 24.1461 39.6758 23.307L36.6932 20.3246C35.8542 19.4857 35.3921 18.3699 35.3921 17.1836C35.3921 15.997 35.8542 14.8814 36.693 14.0425L44.2911 6.44445C45.1301 5.60551 46.2458 5.14355 47.4322 5.14355C48.6187 5.14355 49.7342 5.60563 50.5731 6.44445L53.5559 9.42723C54.3949 10.2662 54.857 11.3819 54.857 12.5683C54.857 13.7547 54.395 14.8704 53.5559 15.7093L45.9581 23.3071C45.119 24.1461 44.0035 24.6083 42.8169 24.6083ZM47.4323 8.7193C47.2982 8.7193 47.0405 8.75234 46.8197 8.97301L39.2219 16.5711C38.8842 16.9088 38.8842 17.4583 39.2219 17.7961L42.2044 20.7786C42.4252 20.9994 42.6829 21.0325 42.8169 21.0325C42.9509 21.0325 43.2084 20.9995 43.4293 20.7788L51.0275 13.1807C51.2481 12.9601 51.2812 12.7023 51.2812 12.5683C51.2812 12.4343 51.2481 12.1766 51.0275 11.9558L48.0447 8.97301C47.824 8.75223 47.5662 8.7193 47.4323 8.7193Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M10.6911 52.2915L7.70854 49.3089C6.67202 48.2724 6.67202 46.5919 7.70854 45.5554L15.3066 37.9573C16.3432 36.9208 18.0236 36.9208 19.0601 37.9573L22.0427 40.9399C23.0792 41.9764 23.0792 43.6569 22.0427 44.6934L14.4446 52.2915C13.4082 53.328 11.7276 53.328 10.6911 52.2915Z"
      fill="#F7CF47"
    />
    <path
      d="M12.5684 54.8568C11.3819 54.8568 10.2664 54.3947 9.42746 53.5559L6.44469 50.5731C5.60574 49.7342 5.14355 48.6185 5.14355 47.4322C5.14355 46.2455 5.60563 45.1299 6.44469 44.2911L14.0425 36.6932C14.8815 35.8543 15.997 35.3921 17.1836 35.3921C18.3702 35.3921 19.4857 35.8543 20.3247 36.6933L23.3073 39.6758C24.1463 40.5147 24.6084 41.6304 24.6084 42.8169C24.6084 44.0034 24.1463 45.1189 23.3075 45.9578L15.7093 53.5557C14.8704 54.3947 13.7548 54.8568 12.5684 54.8568ZM17.1839 38.9676C17.0498 38.9676 16.7923 39.0006 16.5714 39.2213L8.97324 46.8194C8.75258 47.0401 8.71953 47.2979 8.71953 47.4319C8.71953 47.5659 8.75258 47.8235 8.97324 48.0444L11.956 51.0271C12.1767 51.2478 12.4345 51.2808 12.5684 51.2808C12.7024 51.2808 12.9602 51.2478 13.181 51.0271L20.7788 43.429C21.1166 43.0913 21.1166 42.5418 20.7788 42.2041L17.7963 39.2215C17.5754 39.0006 17.3177 38.9676 17.1839 38.9676Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M37.9576 40.9399L40.9401 37.9573C41.9766 36.9208 43.6571 36.9208 44.6936 37.9573L52.2917 45.5554C53.3282 46.5919 53.3282 48.2724 52.2917 49.3089L49.3092 52.2915C48.2726 53.328 46.5922 53.328 45.5557 52.2915L37.9576 44.6934C36.921 43.657 36.921 41.9764 37.9576 40.9399Z"
      fill="#F7CF47"
    />
    <path
      d="M47.432 54.8568C46.2455 54.8568 45.13 54.3947 44.2911 53.5559L36.693 45.9578C35.854 45.1189 35.3921 44.0031 35.3921 42.8169C35.3921 41.6302 35.8542 40.5146 36.693 39.6758L39.6758 36.6932C40.5145 35.8543 41.6302 35.3921 42.8167 35.3921C44.0034 35.3921 45.119 35.8543 45.9578 36.6933L53.5557 44.2911C54.3947 45.13 54.8568 46.2458 54.8568 47.4322C54.8568 48.6187 54.3947 49.7342 53.5557 50.5731L50.5731 53.5559C49.7341 54.3947 48.6186 54.8568 47.432 54.8568ZM42.8166 38.9676C42.6827 38.9676 42.4251 39.0006 42.2044 39.2213L39.2215 42.2041C38.8838 42.5418 38.8838 43.0913 39.2215 43.429L46.8196 51.0271C47.0403 51.2478 47.2981 51.2808 47.432 51.2808C47.566 51.2808 47.8238 51.2478 48.0446 51.0271L51.0271 48.0444C51.2479 47.8237 51.2808 47.5659 51.2808 47.4319C51.2808 47.2979 51.2478 47.0402 51.0271 46.8194L43.429 39.2215C43.2083 39.0006 42.9507 38.9676 42.8166 38.9676Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M7.70895 10.6912L10.6915 7.70867C11.728 6.67214 13.4085 6.67214 14.445 7.70867L22.0431 15.3068C23.0796 16.3433 23.0796 18.0237 22.0431 19.0603L19.0605 22.0428C18.024 23.0793 16.3436 23.0793 15.307 22.0428L7.70895 14.4447C6.67254 13.4083 6.67254 11.7277 7.70895 10.6912Z"
      fill="#F7CF47"
    />
    <path
      d="M17.1836 24.6083C15.997 24.6083 14.8814 24.1461 14.0425 23.307L6.44469 15.7092C5.60563 14.8702 5.14355 13.7545 5.14355 12.5682C5.14355 11.3816 5.60563 10.2659 6.44469 9.4271L9.42723 6.44433C10.2663 5.60539 11.3819 5.14343 12.5683 5.14343C13.7547 5.14343 14.8704 5.6055 15.7093 6.44433L23.3074 14.0424C24.1463 14.8814 24.6083 15.9971 24.6083 17.1835C24.6083 18.37 24.1462 19.4855 23.3074 20.3245L20.3246 23.307C19.4857 24.1461 18.37 24.6083 17.1836 24.6083ZM12.5682 8.71929C12.4341 8.71929 12.1764 8.75234 11.9557 8.973L8.97312 11.9555C8.75234 12.1762 8.71941 12.434 8.71941 12.5681C8.71941 12.702 8.75246 12.9596 8.97312 13.1805L16.5712 20.7784C16.792 20.9991 17.0497 21.0323 17.1838 21.0323C17.3177 21.0323 17.5753 20.9993 17.7959 20.7786L20.7788 17.7958C21.1166 17.4581 21.1166 16.9086 20.7788 16.5709L13.1806 8.973C12.9598 8.75222 12.7021 8.71929 12.5682 8.71929Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg3 = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="secondary"
      d="M24.1344 20.3707C27.6361 20.3707 29.207 22.3333 29.8252 23.4862C29.9029 23.6309 30.1136 23.6306 30.1909 23.4858C30.807 22.3329 32.3693 20.3707 35.8705 20.3707C39.859 20.3707 43.3676 23.0933 43.3676 28.0651C43.3676 30.077 42.9577 31.8006 41.8198 33.724C39.5007 37.6439 31.8615 42.7254 30.2923 43.7268C30.1188 43.8375 29.8991 43.8379 29.7253 43.7275C28.1549 42.7309 20.5141 37.6746 18.177 33.724C17.0391 31.8005 16.6318 30.077 16.6318 28.0651C16.6316 23.0933 20.1457 20.3707 24.1344 20.3707Z"
      fill="#F7CF47"
    />
    <path
      d="M30.008 45.5105C29.585 45.5105 29.1721 45.3904 28.8141 45.1635C27.8561 44.5554 19.3778 39.0942 16.7132 34.5901C15.4808 32.5067 14.9312 30.4945 14.9312 28.0653C14.9312 25.2449 15.8951 22.8425 17.7189 21.1182C19.3883 19.5397 21.6666 18.6705 24.1344 18.6705C27.0119 18.6705 28.8678 19.7944 30.0064 20.9255C31.1431 19.7941 32.9957 18.6705 35.8706 18.6705C38.3382 18.6705 40.6157 19.5399 42.2837 21.1188C44.1051 22.843 45.068 25.2452 45.068 28.0655C45.068 30.4914 44.5176 32.5037 43.2831 34.5902C40.6373 39.0626 32.1643 44.5496 31.2067 45.1605C30.8476 45.3894 30.4332 45.5105 30.008 45.5105ZM24.1346 22.0711C21.2465 22.0711 18.3322 23.9245 18.3322 28.0651C18.3322 29.899 18.7112 31.2876 19.6404 32.8582C21.3594 35.764 26.8472 39.8136 30.0064 41.885C33.1648 39.8038 38.6497 35.7426 40.3562 32.8582C41.2873 31.2841 41.6671 29.8955 41.6671 28.0651C41.6671 23.9243 38.7557 22.0711 35.8704 22.0711C33.3943 22.0711 32.2307 23.2761 31.6904 24.2872C31.3585 24.9085 30.7136 25.2949 30.0077 25.2949C29.303 25.2949 28.6587 24.9096 28.3263 24.2894C27.7839 23.2775 26.6162 22.0711 24.1346 22.0711Z"
      fill="#003B4A"
    />
    <path
      d="M29.9995 60C29.7357 60 29.472 59.9389 29.2304 59.816C26.4612 58.4116 22.3042 56.1641 18.227 53.4345C14.3738 50.8549 11.2584 48.3077 8.96732 45.8635C6.75564 43.504 5.5376 40.4214 5.5376 37.1837V12.7907C5.5376 11.9409 6.16467 11.2218 7.00642 11.1061C15.1369 9.98861 22.883 6.21412 28.8179 0.477807C29.477 -0.159106 30.5222 -0.159106 31.1813 0.477807C34.3562 3.54636 38.0182 6.04573 42.065 7.90643C42.9182 8.29865 43.2918 9.30845 42.8997 10.1617C42.5075 11.0152 41.4979 11.3889 40.6444 10.9963C36.7414 9.20193 33.1672 6.85947 29.9993 4.02214C24.0901 9.30752 16.7121 12.8897 8.93849 14.2461V37.1838C8.93849 39.5543 9.83005 41.8109 11.4486 43.5378C13.5631 45.7935 16.4803 48.1726 20.1191 50.6085C23.6954 53.0029 27.3474 55.019 29.9997 56.3891C32.652 55.0187 36.3041 53.0028 39.8804 50.6085C43.5193 48.1723 46.4364 45.7935 48.5508 43.5378C50.1697 41.8109 51.061 39.5543 51.061 37.1838V12.7907C51.061 11.8515 51.8222 11.0903 52.7614 11.0903C53.7005 11.0903 54.4618 11.8515 54.4618 12.7907V37.1838C54.4618 40.4216 53.2437 43.5039 51.032 45.8636C48.7409 48.3078 45.6256 50.855 41.7723 53.4346C37.6951 56.1643 33.5383 58.4117 30.769 59.8162C30.527 59.9387 30.2631 60 29.9995 60Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg4 = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="secondary"
      d="M24.0053 1.67444C27.5841 1.67444 29.1898 3.68034 29.8216 4.85854C29.9009 5.00655 30.1163 5.00643 30.1953 4.85819C30.8249 3.67999 32.4218 1.67444 36.0003 1.67444C40.0768 1.67444 43.6627 4.45717 43.6627 9.53877C43.6627 11.5951 43.2435 13.3566 42.0807 15.3226C39.7105 19.3289 31.9026 24.5225 30.2987 25.546C30.1214 25.6592 29.8969 25.6593 29.7193 25.5467C28.114 24.5281 20.3048 19.3603 17.9161 15.3224C16.753 13.3564 16.3369 11.5949 16.3369 9.53866C16.337 4.45717 19.9289 1.67444 24.0053 1.67444Z"
      fill="#F7CF47"
    />
    <path
      d="M30.0084 27.3055C29.5883 27.3055 29.1784 27.1864 28.8228 26.9611C27.8442 26.34 19.1905 20.7653 16.4751 16.1753C15.2216 14.0566 14.6626 12.01 14.6626 9.53883C14.6626 6.67406 15.641 4.23469 17.492 2.48426C19.1864 0.882305 21.4995 0 24.0053 0C26.9633 0 28.8563 1.1693 30.0067 2.33098C31.1551 1.1693 33.0453 0 36.0003 0C38.506 0 40.8183 0.882656 42.5114 2.48496C44.36 4.2348 45.3372 6.67406 45.3372 9.53883C45.3372 12.0062 44.7773 14.053 43.5219 16.1753C40.8249 20.7343 32.1767 26.3343 31.1995 26.9576C30.843 27.1853 30.431 27.3055 30.0084 27.3055ZM24.0053 3.34898C21.0219 3.34898 18.0115 5.26301 18.0115 9.53883C18.0115 11.4257 18.4013 12.8544 19.3572 14.47C21.1261 17.4596 26.7784 21.6258 30.0067 23.7396C33.2345 21.6159 38.8831 17.4389 40.6394 14.47C41.5974 12.8507 41.9881 11.4221 41.9881 9.53883C41.9881 5.26301 38.9808 3.34898 36.0002 3.34898C33.4379 3.34898 32.2324 4.59879 31.6721 5.64738C31.3433 6.26238 30.7058 6.64383 30.0079 6.64383C29.3114 6.64383 28.6744 6.2632 28.3455 5.6502C27.783 4.6002 26.5732 3.34898 24.0053 3.34898Z"
      fill="#003B4A"
    />
    <path
      d="M49.006 60H10.9939C8.73508 60 6.89746 58.1624 6.89746 55.9036V31.6884C6.89746 29.4297 8.73508 27.592 10.9939 27.592H22.3955C23.3202 27.592 24.07 28.3419 24.07 29.2665V34.5666H37.6045C38.5291 34.5666 39.2789 35.3165 39.2789 36.2411C39.2789 37.1658 38.5291 37.9156 37.6045 37.9156H22.3955C21.4709 37.9156 20.721 37.1657 20.721 36.2411V30.941H10.9939C10.5817 30.941 10.2466 31.2763 10.2466 31.6883V55.9035C10.2466 56.3156 10.5818 56.6508 10.9939 56.6508H49.006C49.4181 56.6508 49.7533 56.3155 49.7533 55.9035V31.6884C49.7533 31.2763 49.418 30.9411 49.006 30.9411H37.6043C36.6796 30.9411 35.9298 30.1913 35.9298 29.2667C35.9298 28.342 36.6797 27.5922 37.6043 27.5922H49.006C51.2648 27.5922 53.1024 29.4298 53.1024 31.6886V55.9037C53.1024 58.1624 51.2648 60 49.006 60Z"
      fill="#003B4A"
    />
    <path
      d="M20.5282 47.2561H17.8369V50.9212C17.8369 51.354 17.3094 51.5704 16.782 51.5704C16.2545 51.5704 15.7271 51.354 15.7271 50.9212V42.3198C15.7271 41.8736 16.2544 41.6842 16.782 41.6842C17.3093 41.6842 17.8369 41.8736 17.8369 42.3198V45.6332H20.5282V42.3198C20.5282 41.8736 21.0556 41.6842 21.583 41.6842C22.1105 41.6842 22.6379 41.8736 22.6379 42.3198V50.9212C22.6379 51.354 22.1106 51.5704 21.583 51.5704C21.0557 51.5704 20.5282 51.354 20.5282 50.9212V47.2561Z"
      fill="#003B4A"
    />
    <path
      d="M26.2074 45.8088H28.2359C28.6416 45.8088 28.8715 46.2012 28.8715 46.6338C28.8715 46.999 28.6821 47.4319 28.2359 47.4319H26.2074V49.7309H29.8319C30.2376 49.7309 30.4675 50.1638 30.4675 50.664C30.4675 51.0967 30.2781 51.5701 29.8319 51.5701H25.0173C24.5575 51.5701 24.0977 51.3538 24.0977 50.9209V42.3333C24.0977 41.9004 24.5575 41.6841 25.0173 41.6841H29.8319C30.2781 41.6841 30.4675 42.1575 30.4675 42.5902C30.4675 43.0906 30.2376 43.5233 29.8319 43.5233H26.2074V45.8088Z"
      fill="#003B4A"
    />
    <path
      d="M32.3059 51.5704C31.8461 51.5704 31.3862 51.3541 31.3862 50.9212V42.3198C31.3862 41.8736 31.9136 41.6842 32.4412 41.6842C32.9685 41.6842 33.4961 41.8736 33.4961 42.3198V49.7311H36.5795C36.9852 49.7311 37.1882 50.191 37.1882 50.6508C37.1882 51.1107 36.9852 51.5705 36.5795 51.5705H32.3059V51.5704Z"
      fill="#003B4A"
    />
    <path
      d="M38.0391 42.3198C38.0391 41.9816 38.35 41.6842 38.8235 41.6842H41.623C43.4082 41.6842 44.8148 42.5228 44.8148 44.7948V44.8623C44.8148 47.1344 43.3542 47.9999 41.4879 47.9999H40.1491V50.921C40.1491 51.3539 39.6217 51.5703 39.0942 51.5703C38.5668 51.5703 38.0393 51.3539 38.0393 50.921V42.3199H38.0391V42.3198ZM40.149 43.5235V46.377H41.4878C42.2452 46.377 42.7051 45.9441 42.7051 45.0246V44.8758C42.7051 43.9561 42.2453 43.5235 41.4878 43.5235H40.149Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg5 = (
  <svg
    width="58"
    height="60"
    viewBox="0 0 58 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="secondary"
      d="M35.0318 26.7145C31.4309 26.7145 29.8153 28.7327 29.1796 29.9183C29.0998 30.0671 28.8831 30.067 28.8034 29.9179C28.1699 28.7323 26.5633 26.7145 22.9624 26.7145C18.8606 26.7145 15.2524 29.5144 15.2524 34.6274C15.2524 36.6964 15.6741 38.4689 16.8443 40.4471C19.229 44.4782 27.0852 49.7041 28.6991 50.7337C28.8776 50.8476 29.1035 50.8477 29.2821 50.7344C30.8972 49.7096 38.7549 44.5096 41.1584 40.4469C42.3286 38.4687 42.7473 36.6962 42.7473 34.6272C42.7476 29.5147 39.1334 26.7145 35.0318 26.7145Z"
      fill="#F7CF47"
    />
    <path
      d="M28.9918 52.5504C28.5576 52.5504 28.1348 52.4267 27.7682 52.1929C26.7843 51.565 18.0741 45.9249 15.355 41.3285C14.0874 39.1856 13.522 37.1189 13.522 34.6276C13.522 31.732 14.5102 29.2661 16.3799 27.4965C18.0919 25.876 20.4299 24.9835 22.9628 24.9835C25.9237 24.9835 27.828 26.1447 28.9933 27.3096C30.1606 26.145 32.0678 24.9835 35.0321 24.9835C37.5653 24.9835 39.9042 25.8757 41.6173 27.496C43.4893 29.2663 44.4788 31.7322 44.4788 34.6276C44.4788 37.1224 43.9143 39.1889 42.6487 41.3285C39.9101 45.9576 31.195 51.5714 30.2102 52.1962C29.8447 52.428 29.4235 52.5504 28.9918 52.5504ZM22.9628 28.4456C19.987 28.4456 16.9842 30.357 16.9842 34.6276C16.9842 36.5134 17.3756 37.9441 18.3347 39.5657C20.0934 42.5381 25.7456 46.7216 28.9935 48.8611C32.2421 46.732 37.8978 42.56 39.6691 39.5656C40.6261 37.9474 41.0167 36.5168 41.0167 34.6275C41.0167 30.3572 38.0109 28.4455 35.0321 28.4455C32.4713 28.4455 31.2658 29.6912 30.7056 30.7361C30.366 31.3685 29.7096 31.7607 28.9921 31.7607C28.2728 31.7607 27.6158 31.3672 27.2772 30.7337C26.7193 29.6901 25.5178 28.4456 22.9628 28.4456Z"
      fill="#003B4A"
    />
    <path
      d="M37.1694 59.9999H11.9538C8.61584 59.9999 5.8999 57.2842 5.8999 53.946V26.4005C5.8999 24.3582 6.92447 22.4778 8.64057 21.3706L24.2263 11.3152C27.1262 9.44408 30.8738 9.44408 33.7738 11.3152L49.3594 21.3706C51.0756 22.4778 52.1001 24.3581 52.1001 26.4005V56.4467C52.1001 57.4027 51.3249 58.1778 50.369 58.1778C49.4131 58.1778 48.6379 57.4028 48.6379 56.4467V26.4005C48.6379 25.5395 48.206 24.7467 47.4822 24.2798L31.897 14.2244C30.1371 13.089 27.863 13.0888 26.103 14.2244L10.5176 24.2798C9.79393 24.7467 9.36209 25.5394 9.36209 26.4005V53.9461C9.36209 55.3754 10.5247 56.5379 11.9538 56.5379H37.1694C38.1253 56.5379 38.9005 57.3128 38.9005 58.269C38.9005 59.2251 38.1253 59.9999 37.1694 59.9999Z"
      fill="#003B4A"
    />
    <path
      d="M56.0609 20.2716C55.7276 20.2716 55.3912 20.1757 55.0941 19.9752L31.9889 4.3841C30.1733 3.15891 27.8269 3.15891 26.0114 4.3841L2.90627 19.9752C2.11409 20.5099 1.03795 20.3012 0.502993 19.5087C-0.0319682 18.7161 0.176977 17.6401 0.969516 17.1054L24.0747 1.51418C27.0668 -0.504727 30.9333 -0.504727 33.9255 1.51418L57.0306 17.1053C57.823 17.64 58.032 18.716 57.4971 19.5086C57.1628 20.0041 56.6168 20.2716 56.0609 20.2716Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg6 = (
  <svg
    width="54"
    height="60"
    viewBox="0 0 54 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="secondary"
      d="M27.0007 27.0456C34.0117 27.0456 39.6953 21.3621 39.6953 14.3511C39.6953 7.34005 34.0117 1.65649 27.0007 1.65649C19.9897 1.65649 14.3062 7.34005 14.3062 14.3511C14.3062 21.3621 19.9897 27.0456 27.0007 27.0456Z"
      fill="#F7CF47"
    />
    <path
      d="M26.9996 28.702C19.0862 28.702 12.6484 22.2643 12.6484 14.351C12.6484 6.4377 19.0862 0 26.9996 0C34.9127 0 41.3506 6.43793 41.3506 14.3511C41.3506 22.2643 34.9129 28.702 26.9996 28.702ZM26.9996 3.31277C20.913 3.31277 15.9612 8.26453 15.9612 14.3511C15.9612 20.4376 20.913 25.3894 26.9996 25.3894C33.0862 25.3894 38.0378 20.4376 38.0378 14.3511C38.0378 8.26465 33.0862 3.31277 26.9996 3.31277Z"
      fill="#003B4A"
    />
    <path
      d="M26.4777 21.1479C24.1995 21.0719 22.3389 19.8949 22.3389 18.6799C22.3389 18.0344 22.9085 17.0851 23.6298 17.0851C24.4271 17.0851 25.0727 18.2052 26.4777 18.452V15.3762C24.731 14.7117 22.6806 13.8954 22.6806 11.4651C22.6806 9.05384 24.4652 7.89591 26.4777 7.61115V7.07947C26.4777 6.81357 26.7814 6.56677 27.1991 6.56677C27.5599 6.56677 27.9206 6.81357 27.9206 7.07947V7.55408C29.0977 7.59205 31.3191 7.8958 31.3191 9.20584C31.3191 9.71853 30.9773 10.7627 30.142 10.7627C29.5154 10.7627 29.1548 10.1551 27.9209 10.0602V12.8321C29.6484 13.4776 31.661 14.3699 31.661 16.9329C31.661 19.2871 30.1423 20.7111 27.9209 21.0718V21.6223C27.9209 21.8882 27.56 22.135 27.1993 22.135C26.7816 22.135 26.4779 21.8882 26.4779 21.6223V21.1477H26.4777V21.1479ZM26.6675 12.3767V10.1173C25.8131 10.2883 25.4524 10.7249 25.4524 11.1806C25.4525 11.7311 25.9461 12.0728 26.6675 12.3767ZM27.7308 15.889V18.4331C28.3763 18.2812 28.8889 17.9204 28.8889 17.2369C28.8888 16.6104 28.4142 16.2117 27.7308 15.889Z"
      fill="#003B4A"
    />
    <path
      d="M43.6165 60H10.3831C6.84111 60 3.95947 57.1185 3.95947 53.5765V33.7495C3.95947 32.8348 4.70104 32.093 5.61592 32.093C6.5308 32.093 7.27236 32.8347 7.27236 33.7495V53.5765C7.27236 55.2917 8.66783 56.6873 10.3832 56.6873H43.6167C45.3321 56.6873 46.7275 55.2918 46.7275 53.5765V42.6071C46.7275 41.6924 47.4691 40.9506 48.384 40.9506C49.2988 40.9506 50.0404 41.6923 50.0404 42.6071V53.5765C50.0401 57.1185 47.1584 60 43.6165 60Z"
      fill="#003B4A"
    />
    <path
      d="M47.582 35.4058H6.41758C3.10398 35.4058 0.408203 32.7101 0.408203 29.3965C0.408203 26.0829 3.10398 23.3871 6.41758 23.3871H9.27285C10.1877 23.3871 10.9293 24.1288 10.9293 25.0435C10.9293 25.9582 10.1877 26.7 9.27285 26.7H6.41758C4.93059 26.7 3.72098 27.9098 3.72098 29.3966C3.72098 30.8833 4.93059 32.0932 6.41758 32.0932H47.582C49.069 32.0932 50.2786 30.8833 50.2786 29.3966C50.2786 27.9098 49.0689 26.7 47.582 26.7H44.7268C43.8119 26.7 43.0703 25.9583 43.0703 25.0435C43.0703 24.1289 43.8119 23.3871 44.7268 23.3871H47.582C50.8956 23.3871 53.5914 26.0829 53.5914 29.3965C53.5914 32.7101 50.8956 35.4058 47.582 35.4058Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg7 = (
  <svg
    width="60"
    height="44"
    viewBox="0 0 60 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.573 34.7999C12.2026 34.8 10.8326 34.2786 9.78978 33.2356L9.77736 33.2233C7.80942 31.255 7.75985 28.1986 9.65713 25.7905C13.0955 21.4264 23.2244 7.40724 23.3264 7.26603C23.3744 7.1997 23.4274 7.13724 23.4853 7.07935C24.9775 5.58743 27.1122 4.85524 29.2051 5.11598L36.9674 5.71048C37.8127 5.77528 38.4454 6.51298 38.3806 7.35802C38.316 8.2033 37.5774 8.834 36.7331 8.77119L28.932 8.17365C28.9043 8.17154 28.8769 8.16873 28.8494 8.16509C27.7195 8.01697 26.5643 8.39209 25.7368 9.17174C24.7127 10.5888 15.4242 23.4311 12.0685 27.6899C11.3047 28.6594 10.9714 30.075 11.9483 31.0521L11.9606 31.0642C12.85 31.9537 14.2978 31.954 15.1871 31.0642L20.6813 25.5463C21.2792 24.9454 22.2513 24.9434 22.852 25.5417C23.4528 26.1397 23.455 27.1118 22.8566 27.7124L17.36 33.2328C16.3154 34.2777 14.9438 34.7999 13.573 34.7999Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M41.4527 25.3584L30.7024 25.445C28.5947 25.445 26.8858 23.7362 26.8858 21.6284V21.611C26.8858 19.7596 28.2043 18.2166 29.9534 17.8684H5.26783C3.20626 17.8684 1.53516 19.5396 1.53516 21.6011V38.4718C1.53516 40.5334 3.20637 42.2045 5.26783 42.2045H41.7586C43.8202 42.2045 45.4913 40.5333 45.4913 38.4718V23.838C44.3709 24.8035 42.9413 25.3463 41.4527 25.3584Z"
      fill="#F7CF47"
    />
    <path
      d="M41.7583 43.7393H5.2676C2.36321 43.7393 0 41.3764 0 38.4719V21.6011C0 18.6966 2.36321 16.3335 5.2676 16.3335H29.953C30.7426 16.3335 31.4031 16.9323 31.4806 17.718C31.5579 18.5036 31.0268 19.2197 30.2527 19.3738C29.1909 19.585 28.4204 20.5259 28.4204 21.6109V21.6283C28.4204 22.8863 29.4439 23.9098 30.702 23.9098L41.4401 23.8232C42.5573 23.8142 43.6401 23.4065 44.4889 22.6752C44.9436 22.2835 45.5847 22.1928 46.1303 22.4427C46.676 22.6929 47.0257 23.238 47.0257 23.8381V38.4719C47.0259 41.3764 44.6628 43.7393 41.7583 43.7393ZM5.2676 19.4032C4.05564 19.4032 3.06986 20.3891 3.06986 21.601V38.4717C3.06986 39.6835 4.05576 40.6692 5.2676 40.6692H41.7582C42.9701 40.6692 43.9559 39.6833 43.9559 38.4717V26.4621C43.1596 26.7385 42.3184 26.8863 41.4647 26.8932L30.7145 26.9798C27.7512 26.9798 25.3507 24.5792 25.3507 21.6284V21.6111C25.3507 20.8301 25.5211 20.081 25.8294 19.4034H5.2676V19.4032Z"
      fill="#003B4A"
    />
    <path
      d="M23.4315 43.7115C15.8911 43.7115 9.75635 37.5767 9.75635 30.0363C9.75635 22.4959 15.8911 16.3611 23.4315 16.3611C24.2792 16.3611 24.9665 17.0484 24.9665 17.896C24.9665 18.7436 24.2792 19.4309 23.4315 19.4309C17.5837 19.4309 12.8262 24.1884 12.8262 30.0363C12.8262 35.8842 17.5837 40.6416 23.4315 40.6416C29.2794 40.6416 34.037 35.8842 34.037 30.0363C34.037 29.1887 34.7243 28.5014 35.5719 28.5014C36.4195 28.5014 37.1069 29.1887 37.1069 30.0363C37.1069 37.5767 30.9722 43.7115 23.4315 43.7115Z"
      fill="#003B4A"
    />
    <path
      d="M22.9327 36.5363C20.7539 36.4637 18.9746 35.3379 18.9746 34.176C18.9746 33.5587 19.5193 32.6508 20.2093 32.6508C20.9718 32.6508 21.5892 33.722 22.9327 33.9582V31.0168C21.2623 30.3813 19.3014 29.6007 19.3014 27.2766C19.3014 24.9707 21.0082 23.8633 22.9327 23.591V23.0826C22.9327 22.8284 23.2231 22.5923 23.6227 22.5923C23.9677 22.5923 24.3127 22.8283 24.3127 23.0826V23.5365C25.4384 23.5728 27.5626 23.8633 27.5626 25.116C27.5626 25.6063 27.2357 26.6049 26.4369 26.6049C25.8377 26.6049 25.4927 26.0239 24.3125 25.9331V28.5838C25.9647 29.2012 27.8893 30.0544 27.8893 32.5055C27.8893 34.7569 26.4368 36.1185 24.3125 36.4637V36.9902C24.3125 37.2444 23.9675 37.4805 23.6225 37.4805C23.2231 37.4805 22.9325 37.2445 22.9325 36.9902V36.5363H22.9327V36.5363ZM23.1143 28.1481V25.9874C22.2972 26.1509 21.9523 26.5685 21.9523 27.0042C21.9522 27.5308 22.4244 27.8576 23.1143 28.1481ZM24.131 31.5071V33.9401C24.7483 33.7949 25.2385 33.4499 25.2385 32.7963C25.2385 32.197 24.7846 31.8158 24.131 31.5071Z"
      fill="#003B4A"
    />
    <path
      d="M30.7144 27.1015C27.7511 27.1015 25.3506 24.7009 25.3506 21.7501V21.7328C25.3506 18.7819 27.7512 16.3813 30.702 16.3813H42.2776C43.1252 16.3813 43.8125 17.0686 43.8125 17.9162C43.8125 18.7638 43.1252 19.4511 42.2776 19.4511H30.702C29.444 19.4511 28.4204 20.4748 28.4204 21.7328V21.7501C28.4204 23.0081 29.444 24.0317 30.702 24.0317L41.44 23.9451C42.6896 23.9351 43.8642 23.443 44.7477 22.5594L52.5722 14.7349C53.1717 14.1357 54.1433 14.1357 54.743 14.7349C55.3424 15.3343 55.3424 16.3062 54.743 16.9057L46.9184 24.7302C45.4617 26.1869 43.5249 26.9983 41.4649 27.0149L30.7144 27.1015Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M58.4651 10.9732L49.2876 1.79565L43.0556 8.02764L52.2331 17.2052L58.4651 10.9732Z"
      fill="#F7CF47"
    />
    <path
      d="M52.2327 18.741C51.8398 18.741 51.4471 18.591 51.1473 18.2914L41.9696 9.11368C41.6817 8.82575 41.52 8.4354 41.52 8.02829C41.52 7.62118 41.6817 7.23094 41.9696 6.9429L48.202 0.710396C48.49 0.422584 48.8803 0.260864 49.2874 0.260864C49.6946 0.260864 50.0848 0.422701 50.3728 0.710396L59.5506 9.88817C60.15 10.4876 60.15 11.4594 59.5506 12.059L53.3181 18.2914C53.0182 18.591 52.6254 18.741 52.2327 18.741ZM45.2257 8.0284L52.2326 15.0353L56.2944 10.9734L49.2876 3.96657L45.2257 8.0284Z"
      fill="#003B4A"
    />
  </svg>
);

const Svg8 = (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.5835 55.812H14.4268C11.9167 55.812 9.50566 54.6235 7.97672 52.6327L1.23949 43.8597C1.01227 43.5638 0.890039 43.2007 0.892266 42.8277L0.930703 36.1608C0.936094 35.2375 1.68621 34.492 2.60918 34.492C2.6107 34.492 2.61199 34.492 2.61352 34.492C4.60535 34.4972 7.46121 34.5061 9.75516 34.5201C10.7673 34.5261 11.6244 34.5328 12.2307 34.5403C13.5202 34.5561 13.8667 34.5602 14.3619 35.0544L24.24 44.9325C25.3812 46.0737 27.2379 46.0737 28.3791 44.9325L28.3939 44.9176C29.535 43.7766 29.535 41.9197 28.3939 40.7786L19.157 31.6895C18.0497 30.5999 16.5843 29.9998 15.0309 29.9998H1.67848C0.751406 29.9999 0 29.2485 0 28.3214C0 27.3945 0.751406 26.6429 1.67848 26.6429H15.0308C17.4709 26.6429 19.7725 27.5854 21.5114 29.2969L30.7577 38.3953C33.2173 40.8549 33.2173 44.8412 30.7675 47.2913L30.7527 47.306C28.3025 49.7561 24.3162 49.7561 21.8662 47.306L12.4603 37.9001C11.4014 37.885 8.87262 37.8675 4.27793 37.8535L4.25238 42.2714L10.639 50.5879C11.5368 51.7569 12.9527 52.455 14.4267 52.455H53.5834C55.1972 52.455 56.5103 51.1422 56.5103 49.5281V49.5072C56.5103 47.8934 55.1974 46.5803 53.5834 46.5803H37.3835C36.4566 46.5803 35.705 45.8289 35.705 44.9018C35.705 43.9747 36.4564 43.2233 37.3835 43.2233H53.5833C57.048 43.2233 59.867 46.0422 59.867 49.507V49.528C59.8672 52.9931 57.0483 55.812 53.5835 55.812Z"
      fill="#003B4A"
    />
    <path
      className="secondary"
      d="M42.6089 37.2899C51.2863 37.2899 58.3208 30.2555 58.3208 21.578C58.3208 12.9006 51.2863 5.86609 42.6089 5.86609C33.9314 5.86609 26.897 12.9006 26.897 21.578C26.897 30.2555 33.9314 37.2899 42.6089 37.2899Z"
      fill="#F7CF47"
    />
    <path
      d="M42.6097 38.9689C33.0206 38.9689 25.2192 31.1677 25.2192 21.5784C25.2192 11.9893 33.0204 4.18811 42.6097 4.18811C52.1989 4.18811 60 11.9893 60 21.5784C60.0003 31.1677 52.1989 38.9689 42.6097 38.9689ZM42.6097 7.54495C34.8716 7.54495 28.5762 13.8403 28.5762 21.5784C28.5762 29.3165 34.8717 35.6119 42.6097 35.6119C50.3478 35.6119 56.6432 29.3164 56.6432 21.5784C56.6432 13.8404 50.3479 7.54495 42.6097 7.54495Z"
      fill="#003B4A"
    />
    <path
      d="M41.9634 29.991C39.1437 29.897 36.8408 28.4401 36.8408 26.9363C36.8408 26.1373 37.5458 24.9625 38.4387 24.9625C39.4256 24.9625 40.2245 26.3489 41.9634 26.6543V22.8476C39.8016 22.0252 37.2638 21.0148 37.2638 18.0069C37.2638 15.0226 39.4726 13.5893 41.9634 13.2369V12.579C41.9634 12.2501 42.3394 11.9446 42.8564 11.9446C43.3029 11.9446 43.7494 12.2501 43.7494 12.579V13.1665C45.2063 13.2135 47.9556 13.5894 47.9556 15.2108C47.9556 15.8453 47.5327 17.1376 46.4987 17.1376C45.7233 17.1376 45.2768 16.3856 43.7494 16.2682V19.699C45.8877 20.498 48.3785 21.6024 48.3785 24.7746C48.3785 27.6884 46.4987 29.4508 43.7494 29.8972V30.5787C43.7494 30.9076 43.3029 31.2131 42.8564 31.2131C42.3395 31.2131 41.9634 30.9076 41.9634 30.5787V29.991ZM42.1984 19.1349V16.3385C41.141 16.55 40.6945 17.0905 40.6945 17.6544C40.6945 18.3359 41.3055 18.7589 42.1984 19.1349ZM43.5143 23.482V26.6308C44.3133 26.4428 44.9477 25.9963 44.9477 25.1503C44.9477 24.3749 44.3603 23.8814 43.5143 23.482Z"
      fill="#003B4A"
    />
  </svg>
);

const ThreestepBlog = () => {
  const {t} = useTranslation();
  return (
    <>
      {[
        {
          description: t("quickRegistration"),
          title: t("quickRegistrationDesc"),
          svg: Svg1,
        },
        {
          description: t("submitProject"),
          title: t("submitProjectDesc"),
          svg: Svg2,
        },
        { description: t("getFunded"), title: t("getFundedDesc"), svg: Svg3 },
        {
          description: t("receiveFunds"),
          title: t("receiveFundsDesc"),
          svg: Svg4,
        },
      ].map((item, ind) => (
        <div className="my-2 col-xl-6 col-lg-4  col-sm-6" key={ind}>
          <div
            className="h-100  icon-bx-wraper text-center style-4 m-b30"
            data-wow-delay="0.2s"
          >
            <div className="icon-bx-sm m-b20">
              <Link to={"/project-categories"} className="icon-cell">
                {item.svg}
              </Link>
            </div>
            <div className="icon-content">
              <div className="separator"></div>
              <p style={{ fontWeight: "550" }} className="">
                {item.description}
              </p>
              <p>{item.title}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ThreestepBlog;
