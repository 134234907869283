import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    isLogin: false,
}
export const LoginSlice = createSlice({
    name:"Login",
    initialState,
    reducers:{
        showLogin:(state, action)=>{
        state.isLogin = action.payload;
    }}
})
export const  {showLogin} = LoginSlice.actions;
export default LoginSlice.reducer;