import React from 'react'
import ProductMain from '../components/ProductTools/ProductMain'
import ProductList from '../components/ProductTools/ProductList'
import { Swiper } from 'swiper'
import CardSwiper from '../components/ProductTools/SwipperCard'
import PartnershipSlider from '../components/PartnershipSlider'


export default function Product() {
  return (
    <div>
      <ProductMain/>
      <ProductList/>
      <PartnershipSlider/>
      <CardSwiper/>
    </div>
  )
}
