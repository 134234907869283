/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Element, Link } from 'react-scroll';
import "./glossary.css";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
const pitchData = [
  ['section1', {
    name: 'Section 1',
    image: 'https://via.placeholder.com/600x300?text=Section+1',
    description: 'Description for section 1'
  }],
  ['section2', {
    name: 'Section 2',
    image: 'https://via.placeholder.com/600x300?text=Section+2',
    description: 'Description for section 2'
  }],
  ['section3', {
    name: 'Section 3',
    image: 'https://via.placeholder.com/600x300?text=Section+3',
    description: 'Description for section 3'
  }],
  ['section4', {
    name: 'Section 4',
    image: 'https://via.placeholder.com/600x300?text=Section+4',
    description: 'Description for section 4'
  }],
  ['section5', {
    name: 'Section 5',
    image: 'https://via.placeholder.com/600x300?text=Section+5',
    description: 'Description for section 5'
  }],
];

const MyComponent = () => {
  const [activeSection, setActiveSection] = useState(null);
  const api_url = process.env.REACT_APP_INVEST_MAP_API;
  const [section, setSection] =useState(null)
  const [detail, setDetail] = useState(null);
  const {id} = useParams();
  const {i18n} = useTranslation();
  const getDetail = async (language) =>{
    try {
      const res = await axios.get(`${api_url}/api/glossaries/?glossary_id=${id}&&language=${language}`);
      setDetail(res?.data);
      setSection(res?.data?.sections);
      console.log(res?.data);
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(()=>{
    getDetail(i18n.language);
  },[i18n.language])

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  return (
    <div className='container wrapper my-4' style={{ display: 'flex' }}>
      <nav
        style={{
          width: '300px',
          flexShrink: 0,
          height: '100vh',
          position: 'sticky',
          top: '100px',
          overflowY: 'auto',
          backgroundColor: '#f8f9fa',
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          zIndex: 1,
        }}
      >
        <ul style={{ listStyleType: 'none', padding: '20px', margin: '0' }}>
          <h4 className='py-3'>Table of contents</h4>
          {section&&section.map((item, index) => (
            <li
              key={index}
              className={`${activeSection === index?"activ":"" } nav-li`}
              style={{
              
                // backgroundColor: activeSection === key ? 'green' : 'transparent', // Yashil rang
                // color: activeSection === key ? '#155724' : 'inherit', // Yashil matn
                cursor: 'pointer',
              }}
              // onClick={() => {
              //   handleSetActive(key);
              //   document.querySelector(`#${key}`)?.scrollIntoView({ behavior: 'smooth' });
              // }}
            >
              <Link
                className='nav-link'
                to={index}
                spy={true}
                smooth={true}
                duration={0}
                offset={-50}
                onSetActive={handleSetActive}
                // style={{color: activeSection === key ? 'white' : 'green',}}
              >
                {item?.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div style={{ flexGrow: 1 }} className="section1">
        {section&&section.map((item, index) => (
          <Element
            key={index}
            name={index}
            className="section"
            style={{
              marginBottom: '20px',
              borderBottom: '1px solid #ddd'
            }}
          >
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <img
                src="https://via.placeholder.com/600x300?text=Section+5"
                alt="Not found"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <h3>{item?.name}</h3>
            <p className="my-3">{item?.description}</p>
          </Element>
        ))}
      </div>
    </div>
  );
};

export default MyComponent;
