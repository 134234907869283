import React, { useState } from "react";
import "./report.css";
import { useDispatch } from "react-redux";
import { getIndustryReport, getReportType, getYearData } from "../../api/getData/Report";
import { saveReport } from "../../features/slice/report";
const REPORT_TYPE_CHOICES = [
  { name:"ecosystem_report",value: "ecosystem_report", label: "Ecosystem Report" },
  { name:"funding_report",value: ",funding_report", label: "Funding Report" },
  { name:"index_report",value: ",index_report", label: "Index Report" },
  { name:"ipo_report",value: ",ipo_report", label: "IPO Report" },
  { name:"sector_report",value: ",sector_report", label: "Sector Report" },
];

const INDUSTRY_CHOICES = [
  { name:"startup_ecosystem",value: "startup_ecosystem", label: "Startup Ecosystem" },
  { name:"ecommerce",value: ",ecommerce", label: "Ecommerce" },
  { name:"fintech",value: ",fintech", label: "Fintech" },
  { name:"deeptech",value: ",deeptech", label: "DeepTech" },
];
const industries = [
  ["agritech", "Agritech"],
  ["annual", "Annual"],
  ["case_study", "Case Study"],
  ["cleantech", "Cleantech"],
  ["consumer_internet", "Consumer Internet"],
  ["consumer_services", "Consumer Services"],
  ["d2c", "D2C"],
  ["deeptech", "DeepTech"],
  ["ecommerce", "Ecommerce"],
  ["edtech", "Edtech"],
  ["electric_vehicle", "Electric Vehicle"],
  ["enterprisetech", "Enterprisetech"],
  ["financials", "Financials"],
  ["fintech", "Fintech"],
  ["foodtech", "Foodtech"],
  ["gaming", "Gaming"],
  ["healthtech", "Healthtech"],
  ["insurtech", "Insurtech"],
  ["internet", "Internet"],
  ["ipo", "IPO"],
  ["media_entertainment", "Media & Entertainment"],
  ["ott", "OTT"],
  ["quarterly", "Quarterly"],
  ["saas", "SaaS"],
  ["sentiment", "Sentiment"],
  ["startup_ecosystem", "Startup Ecosystem"],
  ["super_apps", "Super Apps"],
  ["survey", "Survey"],
  ["unicorns", "Unicorns"],
  ["venture_capital", "Venture Capital"],
  ["vertical_marketplace", "Vertical Marketplace"],
  ["web3", "Web3"],
];
const FilterSidebar = () => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedReports, setSelectedReports] = useState({
    ecosystem_report:"",
    funding_report:"",
    index_report:"",
    ipo_report:"",
    sector_report:"",

  });
  const [selectedIndustries, setSelectedIndustries] = useState({
    startup_ecosystem: "",
    ecommerce: "",
    fintech: "",
    deeptech: "",
  });
  const handleReportCheckboxChange = async(e) => {
   const {name, value, checked} = e.target;
   setSelectedReports({...selectedReports, [name]:checked?value:""});
   try {
    const state={
      ...selectedReports, [name]:checked?value:""
    }
    const res = await getReportType(state);
    dispatch(saveReport(res?.data?.results));
   } catch (error) {
    console.log(error)
   }
  };

  const handleIndustryCheckboxChange = async (e) => {
    const { name, value, checked } = e.target;
      setSelectedIndustries({ ...selectedIndustries, [name]: checked?value:"" });  
    try {
      const state = {
        ...selectedIndustries,
        [name]: checked ? value : "",
      };
      const res = await getIndustryReport(state);
      dispatch(saveReport(res?.data?.results));
    } catch (error) {
      console.log(error);
    }
  };
 //
 const handleYearChange = async(e) => {
  const newYear = e.target.value; // Tanlangan yangi yil qiymatini olish
  setSelectedYear(newYear); 
  try {
    const res = await getYearData(newYear);
    dispatch(saveReport(res?.data?.results));
  } catch (error) {
    console.log(error)
  }
};
//
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedOptions((prev) => [...prev, value]);
      console.log(selectedOptions);
    } else {
      setSelectedOptions((prev) => prev.filter((option) => option !== value));
    }
  };
  

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
// 
  
  

  return (
    <div className="filter-sidebar">
      {/* Category Filter */}
      <div className="filter-section">
        <h6>1. Filter By Industry</h6>
        <div className="dropdown">
          <button className="dropdown-button">Select categories</button>
          <div className="dropdown-content">
            {industries.map(([value, label]) => (
              <label key={value} className="dropdown-item">
                <input
                  type="checkbox"
                  value={value}
                  onChange={handleCheckboxChange}
                />{" "}
                {label}
              </label>
            ))}
          </div>
        </div>
        <div className="mt-2">
          {INDUSTRY_CHOICES.map((industry) => (
            <label className="dropdown-item" key={industry.value}>
              <input
                type="checkbox"
                name={industry.name}
                value={industry.value}
                onChange={handleIndustryCheckboxChange}
              />{" "}
              {industry.label}
            </label>
          ))}
        </div>
      </div>

      {/* Report Type Filter */}
      <div className="filter-section">
        <h6>2. Filter By Report Type</h6>
        {REPORT_TYPE_CHOICES.map((report) => (
          <label className="dropdown-item" key={report.value}>
            <input
              type="checkbox"
              name={report.name}
              value={report.value}
              onChange={handleReportCheckboxChange}
            />{" "}
            {report.label}
          </label>
        ))}
      </div>

      {/* Publishing Date Filter */}
      <div className="filter-section">
        <h6>3. Publishing Date</h6>
        <select value={selectedYear} onChange={handleYearChange}>
          <option value="">Year</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
        </select>
      </div>
    </div>
  );
};

export default FilterSidebar;
