/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FooterCommonData = ({ logoImage, iconStyle }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-xl-4 col-lg-12 col-md-12">
        <div className="widget widget_about">
          <div className="footer-logo logo-white">
            <Link to={"/"}>
              <img className="w-25" src={logoImage} alt="" />
            </Link>
          </div>
          <p>
            {t("head")}
          </p>
          <div className="dz-social-icon style-1">
            <ul>
              <li>
                <a
                  target="_blank"
                  className="fab fa-facebook-f"
                  rel="noreferrer"
                  href="#"
                ></a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  className="fab fa-instagram"
                  rel="noreferrer"
                  href="#"
                ></a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  className="fab fa-twitter"
                  rel="noreferrer"
                  href="#"
                ></a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  className="fab fa-youtube"
                  rel="noreferrer"
                  href="#"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-2 col-lg-4 col-md-3 col-6">
        <div className="widget widget_services">
          <h5 className="footer-title">{t("company")}</h5>
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>
              <Link to={"/investing"}>{t("investing")}</Link>
            </li>
            <li>
              <Link to={"/raising"}>{t("raising")}</Link>
            </li>
            <li>
              <Link to={"/academy"}>{t("academy")}</Link>
            </li>
            <li>
              <Link to={"/club"}>{t("club")}</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-xl-2 col-lg-4 col-md-3 col-6">
        <div className="widget widget_services">
          <h5 className="footer-title"> {t("resource")}</h5>
          <ul>
            <li>
              <Link to={"/about-us"}>{t("about_us")}</Link>
            </li>
            {/* <li><Link to={"/volunteer"}>Volunteer</Link></li> */}
            <li>
              <Link to={"/investing"}>{t("events")}</Link>
            </li>
            <li>
              <Link to={"/project"}>{t("project")}</Link>
            </li>
            <li>
              <Link to={"/contact"}>{t("contact_us")}</Link>
            </li>
            <li>
              <Link to={"/faq"}>{t("faq")}</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6">
        <div className="widget widget_getintuch">
          <h5 className="footer-title">{t("get-in")}</h5>
          <ul>
            <li>
              {iconStyle ? (
                <i className="fas fa-map-marker-alt text-primary"></i>
              ) : (
                <i className="fas fa-map-marker-alt"></i>
              )}
              <span>
                832 Toshkent shahar, Mirzo Ulug'bek tumani, Ziyolilar ko'chasi,
                9 uy
              </span>
            </li>
            <li>
              {iconStyle ? (
                <i className="fa-solid fa-phone text-primary"></i>
              ) : (
                <i className="fa-solid fa-phone"></i>
              )}
              <span>(+998)93 392 72 22</span>
            </li>
            <li>
              {iconStyle ? (
                <i className="fa fa-envelope text-primary"></i>
              ) : (
                <i className="fa fa-envelope"></i>
              )}
              <span>jonibek.greatsoft@gmail.com</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default FooterCommonData;
