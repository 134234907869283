import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import "./investor.css";
const InvestorList = () => {
  const data = [
    {
      description: "lorem",
      fullName: "Sahin Boydas",
      jobTitle: "Head of Turkiye, Distribute",
      organization: "Gusto",
      location: "San Francisco Bay Area, CA",
      rank: 12,
    },
    {
      description: "lorem",
      fullName: "Alex Konviser",
      jobTitle: "Founder",
      organization: "TON Provider",
      location: "Zürich, Zurich, Switzerland",
      rank: 34,
    },
    {
      description: "lorem",
      fullName: "Hesham Zreik",
      jobTitle: "CEO",
      organization: "FasterCapital",
      location: "Dubai, UAE",
      rank: 56,
    },
    {
      description: "lorem",
      fullName: "Bashar Hamood",
      jobTitle: "Venture Partner",
      organization: "FasterCapital",
      location: "UAE, Asia",
      rank: 78,
    },
    {
      description: "lorem",
      fullName: "Nicholas Chirls",
      jobTitle: "Founder & Partner",
      organization: "Notation Capital",
      location: "New York, NY, USA",
      rank: 90,
    },
  ];

  return (
    <>
      <Container>
        <div className="find-bx mt-5 py-2">
          <form className="">
            <div className="row align-items-center d-flex gap-md-0 gap-3 ">
              <div className="col-lg-9 col-12">
                <div class="row">
                  <div className="col-lg-4 col-md-12  ">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search placeholder"
                      />
                      <div className="input-group-prepend">
                        <button className="btn">
                          <i className="las la-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-12">
                    <div className="">
                      <Dropdown className="select-drop-2 ">
                        <Dropdown.Toggle
                          as="div"
                          className="i-false select-drop-btn-2   p-0 _dpdown"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <svg
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              fill="rgb(65,150,135)"
                              className="default inherit"
                            >
                              <path d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z"></path>
                            </svg>
                            <span className="fs-6">Overview</span>
                          </div>
                          <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            <div class="container">
                            <Row className="mb-1">
                            <Col>
                              <Form.Group controlId="headquartersLocation">
                                <Form.Label>Headquarters Location</Form.Label>
                                
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              <Form.Group controlId="type">
                                <Form.Label>Type</Form.Label>
                                <Form.Check
                                  type="checkbox"
                                  label="Venture Capital"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Individual/Angel"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Private Equity Firm"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Accelerator"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Button variant="link">+ MORE OPTIONS</Button>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Form.Group controlId="numberOfInvestments">
                                <Form.Label>Number of Investments</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter number"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group controlId="numberOfExits">
                                <Form.Label>Number of Exits</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter number"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Form.Group controlId="diversitySpotlight">
                                <Form.Label>Diversity Spotlight</Form.Label>
                                <Form.Check type="checkbox" label="Women Led" />
                                <Form.Check
                                  type="checkbox"
                                  label="Black / Latinx Found"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                            </div>
                          
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="  col-lg-3 col-md-12">
                    <div className="">
                      <Dropdown className="select-drop-2">
                        <Dropdown.Toggle
                          as="div"
                          className="i-false select-drop-btn-2 p-0 _dpdown"
                        >
                          <span>Investments</span>
                          <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>All Category</Dropdown.Item>
                          <Dropdown.Item>Technology</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="  col-lg-2 col-md-12">
                    <div className="">
                      <Dropdown className="select-drop-2">
                        <Dropdown.Toggle
                          as="div"
                          className="i-false select-drop-btn-2 p-0 _dpdown"
                        >
                          <span>Contacts</span>
                          <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>All Category</Dropdown.Item>
                          <Dropdown.Item>Technology</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 d-flex gap-3  justify-content-end">
                <button
                  onClick={(e) => e.preventDefault()}
                  className="bg-transparent _edit d-flex align-items-center gap-1"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    className="default inherit"
                    fill="green"
                  >
                    <path d="M9,4V20H5V4H9M9,2H5A2,2,0,0,0,3,4V20a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V4A2,2,0,0,0,9,2Z"></path>
                    <path d="M19,4V20H15V4h4m0-2H15a2,2,0,0,0-2,2V20a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2Z"></path>
                    <polygon points="9 14 5 14 5 16 9 16 9 14 9 14"></polygon>
                    <polygon points="19 14 15 14 15 16 19 16 19 14 19 14"></polygon>
                    <polygon points="9 8 5 8 5 10 9 10 9 8 9 8"></polygon>
                    <polygon points="19 8 15 8 15 10 19 10 19 8 19 8"></polygon>
                  </svg>
                  <span>Edit View </span>
                </button>
                <button
                  onClick={(e) => e.preventDefault()}
                  className="bg-transparent _edit d-flex align-items-center gap-1"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="green"
                    className="default pro-gold"
                  >
                    <polygon points="13.24 0 5.74 14.61 10.96 14.61 10.96 24 18.26 9.39 13.24 9.39 13.24 0"></polygon>
                  </svg>
                  <span>Export</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <Table responsive className="my-3 my-md-4">
          <thead className="custom-thead">
            <tr>
              
              <th className="_th">Org Name</th>
              <th className="_th">Portfolio Count</th>
              <th className="_th">Number of exist</th>
              <th className="_th">Location</th>
              <th className="_th">Type</th>
              <th className="_th">Description</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {/* <td>
                  <Button
                    variant="outline-primary"
                    className="py-1 px-3"
                    size="sm"
                  >
                    Save
                  </Button>
                </td> */}
                <td>{item.fullName}</td>
                <td>{item.jobTitle}</td>
                <td>{item.organization}</td>
                <td>{item.location}</td>
                <td>{item.rank}</td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default InvestorList;
