import { IMAGES } from "../../constant/theme";
import { useTranslation } from "react-i18next";
const MainSliderIndex3 = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="banner-inner"
        style={{ backgroundImage: "url(" + IMAGES.SliderBg2 + ")" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-md-5">
              <div className="">
                <img src={IMAGES.SliderPic2} alt="Not defined" />
              </div>
            </div>
            <div className="col-xl-6 col-md-7">
              <div className="banner-content">
                <div className="top-content">
                  <h5 className="sub-title text-primary">{t("learn")}</h5>
                  <h1 className="title">{t("invest-a")}</h1>
                  <p>{t("invest-a-desc")}</p>
                  <div className="d-flex align-items-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSliderIndex3;
