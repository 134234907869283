import axios from "axios";

const api_url = process.env.REACT_APP_INVEST_MAP_API;
export const getReport = async (language, query) => {
  try {
    const res = await axios.get(`${api_url}/api/reports/?language=${language}&search=${query}`);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getIndustryReport = async (data, data1) => {
  try {
    const res = await axios.get(
      `${api_url}/api/reports/?industry=${data.startup_ecosystem}${data.ecommerce}${data.fintech}${data.deeptech}`
    );
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getReportType = async (data, data1) => {
  try {
    const res = await axios.get(
      `${api_url}/api/reports/?report_type=${data.ecosystem_report}${data.funding_report}${data.index_report}${data.ipo_report}${data.sector_report}`
    );
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getYearData = async (year) => {
  try {
    const res = await axios.get(`${api_url}/api/reports/?year=${year}`);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getSeachData = async (input, language) => {
  const api=input?`${api_url}/api/reports/?search=${input}`:`${api_url}/api/reports/`;
  try {
    const res = await axios.get(api);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
