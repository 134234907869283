import React, { useEffect, useRef, useState } from "react";
import "./profile.css";

const ProfileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // First dropdown tashqarisidagi kliklarni aniqlash
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="profile-menus" ref={ref}>
      <button className="profile-button  " onClick={toggleMenu}>
        <i
          className="fa-solid fa-circle-user  mt-1 "
          style={{ color: "#1b8271", fontSize:"1.7rem" }}
        />
      </button>
      {isOpen && (
        <div className="dropdown-menus">
          <p>Jack Johnson</p>
          <a onClick={()=>setIsOpen(false)} href="https://app.investmap.uz/auth/sign-in" target="blank">Sign In</a>
          <a onClick={()=>setIsOpen(false)} href="https://app.investmap.uz/auth/sign-in">Sign Up</a>
          {/* <a onClick={()=>setIsOpen(false)} href="/logout">Logout</a> */}
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
