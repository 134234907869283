/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataNotFound from "../DataNotFound/DataNotFound";
const GlossaryList = () => {
  const api_url = process.env.REACT_APP_INVEST_MAP_API;
  const {i18n} = useTranslation();
  const cards = [
    {
      title: "B2B Marketplace",
      description:
        "A B2B marketplace is an ecommerce platform that facilitates business-to-business transactions. It acts as a digital intermediary.",
      buttonText: "READ MORE",
    },
    {
      title: "B2B Model",
      description:
        "A business that sells its products or services to other businesses instead of an individual consumer.",
      buttonText: "READ MORE",
    },
    {
      title: "B2C Marketplace",
      description:
        "A B2C marketplace is an online platform that connects businesses directly with individual consumers (buyers) to sell products and services.",
      buttonText: "READ MORE",
    },
    {
      title: "Balance Sheet",
      description:
        "A balance sheet shows a company's financial health at a specific point in time, its assets, liabilities, and shareholders' equity.",
      buttonText: "READ MORE",
    },
  ];
  const [category, setCategory] = useState("");
  const [query, setQuery] = useState("");
  const categories = [
    { key: "startup_ecosystem", label: "Startup Ecosystem" },
    { key: "funding_investment", label: "Funding and Investment" },
    { key: "technology_innovation", label: "Technology and Innovation" },
    { key: "business_models", label: "Business Models" },
    { key: "entrepreneurship", label: "Entrepreneurship" },
    { key: "market_growth", label: "Market and Growth" },
  ];
  const handelCategory = async (selectedCategory1) => {
    setCategory(selectedCategory1);
    try {
      const res = await axios.get(
        `${api_url}/api/glossaries/?category=${selectedCategory1}`
      );
      if (res && res?.data) {
        setData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    console.log("Searching for:", query, "in category:", category);
  };
  const [data, setData] = useState(null);
  const [glossary, setGlossary] = useState(null);
  const searchGlossary = async () => {
    // const res = await axios.get(`${api_url}/api/glossary/?q=${query}`);
    // setData(res?.data);
  };
  const handelChange = async (e) => {
    setQuery(e.target.value);
    const api = query
      ? `${api_url}/api/glossaries/?q=${query}`
      : `${api_url}/api/glossaries/`;
    const res = await axios.get(`${api}`);
    setData(res?.data);
  };
  const getGlossary = async (language) => {
    try {
      const res = await axios.get(`${api_url}/api/glossaries/?language=${language}&q=${query}`,
       
      );
      console.log(res);
      setData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const nav = useNavigate();
  useEffect(() => {
    getGlossary(i18n.language);
  }, [i18n.language]);
  const navigateItem = (item) => {
    nav(`/glossary/${item}`);
  };
  return (
    <div className="page-content bg-white">
      <div className="find-bx-wrapper" style={{ marginTop: "90px" }}>
        <div className="container">
          <div className="find-bx bg-white">
            <form>
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-4">
                  <Dropdown className="select-drop-2">
                    <Dropdown.Toggle
                      as="div"
                      className="i-false select-drop-btn-2"
                    >
                      <span>{category === "" ? "All Category" : category}</span>
                      <i className="fa-regular fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {categories.map((category) => (
                        <Dropdown.Item
                          key={category.key}
                          onClick={() => handelCategory(category.key)}
                        >
                          {category.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="input-group">
                    <input
                      type="text"
                      value={query}
                      onChange={handelChange}
                      className="form-control"
                      placeholder="Search glossary"
                      onFocus={handelChange}
                    />
                    <div className="input-group-prepend">
                      <button onClick={handleSearch} className="btn">
                        <i className="las la-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <section>
        <Container>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <div key={index}>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <h1
                      style={{
                        color: "black",
                        fontSize: "54px",
                        fontFamily: "serif",
                      }}
                    >
                      {item?.first_letter}
                    </h1>
                  </Col>
                  <Col>
                    <div style={{ height: "2px", background: "green" }}></div>
                  </Col>
                </Row>
                <Row>
                  {item.glossaries.map((item1, index1) => (
                    <Col
                      onClick={() => navigateItem(item1?.id)}
                      key={index1}
                      md={6}
                      className="mb-4"
                    >
                      <Card
                        style={{
                          cursor: "pointer",
                          border: "1px solid #e6e6e6",
                          borderRadius: "12px",
                        }}
                      >
                        {/* <Card.Header style={{ backgroundColor: 'red' }}></Card.Header> */}
                        <Card.Body>
                          <Card.Title
                            style={{ fontSize: "22px", marginBlock: "10px" }}
                          >
                            {item1.name}
                          </Card.Title>
                          <Card.Text
                            style={{
                              fontFamily: "sans-serif",
                              lineHeight: "20px",
                              fontSize: "15px",
                            }}
                          >
                            {item1.description}
                          </Card.Text>
                          <Link className="text-center fs-5">Read More</Link>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ))
          ) : (
            <DataNotFound title="Glossary not found" />
          )}
        </Container>
      </section>
    </div>
  );
};

export default GlossaryList;
