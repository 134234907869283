


import { useTranslation } from "react-i18next";
import TestimonialSlider from "../Home/TestimonialSlider";


const CardSwiper = () => {
 const {t} = useTranslation();


  return (
    <section className="testimonial-wrapper1 content-inner">
          <div
            className="section-head text-center wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <h2 className="title">{t("testimonials")} </h2>
          </div>
          <TestimonialSlider />
        </section>
  );
};

export default CardSwiper;
