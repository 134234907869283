import { configureStore } from "@reduxjs/toolkit";
import toogleSidebar from "./slice/toogleSidebar";
import loginReducer  from "./slice/showLogin";
import reportReducer from "./slice/report"
export  const  store = configureStore({
    reducer:{
        sidebar: toogleSidebar,
        login:loginReducer,
        report: reportReducer
    }
})