import FaqComponent from "../components/FaqComponent";
import ContactComponent from "../components/ContactComponent";
import RaisingBlog from "../components/RaisingBlog";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import RaisingMain from "../components/Home2/Mainslider2";
const Home2 = () => {
  const { t } = useTranslation();
  const accordBlog = [
    { title: t("how_start_funds"), answer: t("how_start_funds_answer") },
    {
      title: t("fundraising_duration"),
      answer: t("fundraising_duration_answer"),
    },
    { title: t("investor_selection"), answer: t("investor_selection_answer") },
    { title: t("fees_involved"), answer: t("fees_involved_answer") },
    { title: t("project_rejected"), answer: t("project_rejected_answer") },
    {
      title: t("fundraising_support"),
      answer: t("fundraising_support_answer"),
    },
    { title: t("funds_disbursement"), answer: t("funds_disbursement_answer") },
  ];
  const api_url = process.env.REACT_APP_INVEST_MAP_API;
  const contact = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    project_name: "",
  };
  const [state, setState] = useState(contact);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const sendSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${api_url}/api/raising/contact/`, state);
      if (res.status === 201) {
        toast.success("Success");
        setState(contact);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const text = "FAQ";

  return (
    <>
      <div className="page-content bg-white">
        <div className="main-bnr-one">
          <RaisingMain />
        </div>
        <section className="clearfix">
          <div className="container">
            <div className="content-inner ">
              <div className="container">
                <div
                  className="section-head text-center wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <h2 className="title">{t("how-it")}</h2>
                </div>
                <div className="row justify-content-center">
                  <RaisingBlog />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="content-inner"
          style={{ background: "darkturquoise" }}
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                <div className="section-head">
                  <h2 className="title text-white">{t("form_title")}</h2>
                </div>
              </div>
              <div className="col-lg-8">
                <form className="dzForm" onSubmit={sendSubmit}>
                  <div className="dzFormMsg"></div>
                  <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                    required
                  />
                  <input
                    type="hidden"
                    className="form-control"
                    name="reCaptchaEnable"
                    value="0"
                  />

                  <div className="row g-4">
                    <div className="col-md-4 col-sm-6">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder={t("firstname")}
                        name="first_name"
                        value={state.first_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder={t("lastname")}
                        name="last_name"
                        value={state.last_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder={t("email_1")}
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder={t("phonenumber")}
                        name="phone_number"
                        value={state.phone_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder={t("project_name")}
                        name="project_name"
                        value={state.project_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn btn-dark btn-block h-100"
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <FaqComponent accordBlog={accordBlog} text={text} />
        <ContactComponent />
      </div>
    </>
  );
};

export default Home2;
