/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ProjectMasonry from "../components/Project/ProjectMasonry";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Project = () => {
  //Api
  const api_url = process.env.REACT_APP_INVEST_MAP_API;
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState("");
  const [notfound, setNotfound] = useState();
  // eslint-disable-next-line no-unused-vars
  const [data, setdData] = useState();
  const [childData, setChildData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.investmap.uz/api/project/visible/", {
        responseType: "json",
      })
      .then((res) => {
        setChildData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(event.target.value);
    const apiUrl = value
      ? `https://api.investmap.uz/api/project/search/?search=${value}`
      : "https://api.investmap.uz/api/project/visible/";
    axios
      .get(`${apiUrl}`, {
        responseType: "json",
      })
      .then((res) => {
        if (res.data && res.data.length === 0) {
          setNotfound(true);
        } else {
          setChildData(res.data);
          console.log(childData);
          setNotfound(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    console.log("salom");
    axios
      .get(`https://api.investmap.uz/api/project/search/?search=${query}`, {
        responseType: "json",
      })
      .then((res) => {
        if (res.data && res.data.length === 0) {
          console.log("No results found");
        } else {
          setChildData(res.data);
          console.log(childData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handelCategory = (category1, item) => {
    setCategory(item);
    axios
      .get(`https://api.investmap.uz/api/project/search/?category=${category1}`)
      .then((res) => {
        if (res.data && res.data.length === 0) {
          console.log("No results found");
          setNotfound(true);
        } else {
          setChildData(res.data);
          console.log(childData);
          setNotfound(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const getAllProjectData = async () => {
    try {
      const res = await axios.get(`${api_url}/api.investmap.uz/api/project/`);
      if (res.status === 200) {
        setdData(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const {t, i18n} = useTranslation();
  useEffect(() => {
    const currentCategory = product.find((item) => item.name === category);
    setCategory(currentCategory?.value || t("all-category"));
  }, [i18n.language]); 
  const product = [
    { id: 0, name: "All Category", value:t("all-category") },
    { id: 1, name: "Health", value:t("health") },
    { id: 2, name: "Financial Technology", value:t("financial") },
    { id: 3, name: "Education", value:t("education") },
    { id: 4, name: "E-commerce", value:t("e-com") },
    { id: 5, name: "Green Technology", value:t("gren") },
    { id: 6, name: "Real Estate", value:t("real-estate") },
    { id: 7, name: "Transportation & Logistics", value:t("transport-l") },
    { id: 8, name: "Media & Entertainment", value:t("media") },
    { id: 9, name: "Food & Beverage", value:t("food-b") },
    { id: 10, name: "Social Impact", value:t("s-impact") },
    { id: 11, name: "Consumer Products", value:t("consume") },
  ]
  const [category, setCategory] = useState(t("all-category"));
  return (
    <>
      <div className="page-content bg-white">
        <div className="find-bx-wrapper " style={{ marginTop: "90px" }}>
          <div className="container">
            <div className="find-bx bg-white">
              <form>
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-4">
                    <div className="">
                      <Dropdown className="select-drop-2">
                        <Dropdown.Toggle
                          as="div"
                          className="i-false select-drop-btn-2"
                        >
                          <span>
                            {category}
                          </span>
                          <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {product.map((item, index) => (
                            <Dropdown.Item
                              onClick={() =>
                                handelCategory(
                                  item.name === "All Category" ? "" : item.name, item.value
                                )
                              }
                            >
                              {item.value}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                        {/* <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handelCategory("")}>
                            All Category
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Technology")}
                          >
                            Technology
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Health & Medicine")}
                          >
                            Health
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handelCategory("Financial Technology")
                            }
                          >
                            Financial Technology
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Education")}
                          >
                            Education
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("E-commerce")}
                          >
                            E-commerce
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Green Technology")}
                          >
                            Green Technology
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Real Estate")}
                          >
                            Real Estate
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handelCategory("Transportation & Logistics")
                            }
                          >
                            Transportation & Logistics
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handelCategory("Media & Entertainment")
                            }
                          >
                            Media & Entertainment
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Food &Beverage")}
                          >
                            Food &Beverage
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Social Impact")}
                          >
                            Social Impact
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handelCategory("Consumer products")}
                          >
                            Consumer Products
                          </Dropdown.Item>
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8">
                    <div className="input-group">
                      <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder={t("find-p")}
                        onFocus={handleInputChange}
                      />
                      <div className="input-group-prepend">
                        <button onClick={handleSearch} className="btn">
                          <i className="las la-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="tag-list">
              <span className="title text-black">Popular Search:</span>
              <Link to={"#"}>Technology,</Link>
              <Link to={"#"}>Charity,</Link>
              <Link to={"#"}>Health,</Link>
              <Link to={"#"}>Medical</Link>
            </div> */}
          </div>
        </div>
        <section className="">
          <div className="container mb-5">
            <ProjectMasonry childData={childData} notfound={notfound} />
          </div>
        </section>
      </div>
    </>
  );
};

export default Project;
